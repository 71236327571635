<template>
	<Result
		class="reset-login"
		:automation-id="getAutomationId('success-registration')"
		:title="$t('title')"
		:sub-title="$t('secondaryTitle')"
		:content="$t('content')"
		icon-color="success-color"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'SuccessResetLogin',
	components: {
		Result
	},
	mixins: [IdMixin]
})
export default class SuccessResetLogin extends Vue {}
</script>

<i18n>
{
    "en": {
		"title": "You're almost done!",
		"secondaryTitle": "We’ve emailed you a link to activate your account.",
		"content": "Please <b>check your email</b> and <b>click the link provided</b> to access your account."
	},
	"fr": {
      "title": "Vous y êtes presque!",
		"secondaryTitle": "Nous vous avons envoyé par courriel un lien d’activation pour votre compte.",
		"content": "Vérifiez votre boîte de courriels et cliquez sur le lien pour accéder à votre compte."
    }
}
</i18n>
