<template>
	<div>
		<BRow>
			<BCol class="header">
				<h1>{{ $t('title') }}</h1>
				<p>{{ $t('subtitle') }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<NeedHelpCard :automation-id="getAutomationId('need-help-form')" />
			</BCol>
		</BRow>
		<BRow v-if="brand && !error">
			<BCol>
				<BaseCard
					ref="contact"
					:title="$t('title2')"
					class="contact-us-form"
					:automation-id="getAutomationId('contact-info-form')"
				>
					<CallUsInformation
						:automation-id="getAutomationId('registration-dialog')"
						@onError="onError()"
					/>
				</BaseCard>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import * as Routes from '@/constants/Routes.js';
import NeedHelpCard from '@/components/login/NeedHelpCard';
import BaseCard from '@/components/common/card/BaseCard.vue';
import CallUsInformation from '@/components/common/CallUsInformation';

// @vue/component
@Component({
	name: 'NeedHelp',
	components: {
		BRow,
		BCol,
		NeedHelpCard,
		BaseCard,
		CallUsInformation
	},
	mixins: [IdMixin, BreadcrumbsManager]
})
export default class Login extends Vue {
	data() {
		return {
			error: false
		};
	}

	// Set the path for the BreadCrumbs Manager.
	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('url.login', 'en'), to: { name: Routes.LOGIN } },
				{ text: this.$t('url.needHelp', 'en') }
			],
			[
				{ text: this.$t('url.login', 'fr'), to: { name: Routes.LOGIN } },
				{ text: this.$t('url.needHelp', 'fr') }
			]
		);
	}

	get brand() {
		// Get the brand from the URL
		let brand = this.$router.currentRoute.query.brand;
		if (brand) brand = brand.toLowerCase(); // make sure lowercase
		if (brand === 'mbc' || brand === 'sbc') {
			// if it's a valid brand show info
			return brand;
		} else {
			// if no brand hide info
			return 'mbc';
		}
	}

	onError() {
		this.error = true;
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
.header {
	margin-top: 30px;
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
{
  "en": {
    "title2": "Still have questions? We're here to help.",
    "url": {
      "login": "Login",
      "needHelp": "Need help?"
    },
    "title": "Need help?",
    "subtitle": "Find answers for some of our most frequently asked questions."
  },
  "fr": {
    "title2": "Vous avez d’autres questions? Nous sommes là pour vous.",
    "url": {
      "login": "Connexion",
      "needHelp": "Besoin d'aide? "
    },
    "title": "Besoin d’aide?",
    "subtitle": "Consultez les réponses à quelques-unes des questions les plus souvent posées."
  }
}
</i18n>
