<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<ValidationProvider
		ref="provider"
		v-slot="{ validated, invalid, failedRules }"
		:vid="validationId"
		:immediate="validateImmediately"
		:skip-if-empty="!validateIfEmpty"
		:rules="validationRules"
		:mode="validationMode"
		:disabled="validationDisabled"
		slim
	>
		<BFormGroup :id="formGroupId">
			<label v-if="showLabel" class="label">{{ label }}</label>
			<BFormTextarea
				:id="textareaId"
				:rows="rows"
				v-bind="$attrs"
				:state="getState(validated, invalid)"
				:value="value"
				:placeholder="placeholder"
				:aria-invalid="invalid"
				:aria-describedby="invalid ? feedbackId : null"
				@input="onInput"
			/>
			<!-- eslint-disable -->
			<BFormInvalidFeedback
				:id="feedbackId"
				:force-show="false"
				aria-live="polite"
				:state="getState(validated, invalid)"
				:data-test-automation-id="getAutomationId('feedback')"
				v-html="getFeedback(validated, failedRules)" 
			>
			</BFormInvalidFeedback>
			<!-- eslint-enable -->
		</BFormGroup>
	</ValidationProvider>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import { BFormGroup, BFormTextarea, BFormInvalidFeedback } from 'bootstrap-vue';
import { ValidationProvider } from 'vee-validate';
// @vue/component
@Component({
	name: 'BaseTextArea',
	components: {
		BFormGroup,
		BFormTextarea,
		BFormInvalidFeedback,
		ValidationProvider
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		value: {
			type: String,
			default: ''
		},
		rows: {
			type: Number,
			default: 3
		},
		// Show or hide the label.
		showLabel: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls if the field is validated immediatly
		 */
		validateImmediately: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the validate if the field is empty
		 */
		validateIfEmpty: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls the validation id. This is needed when cross field validation
		 */
		validationId: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationMode: {
			type: String,
			default: 'eager'
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationDisabled: {
			type: Boolean,
			default: false
		}
	}
})
export default class BaseTextArea extends Vue {
	/**
	 * The unique id for the form group
	 * @return {string}
	 */
	formGroupId = this.getUniqueId('form-group');
	/**
	 * The unique id for the feedback
	 * @return {string}
	 */
	feedbackId = this.getUniqueId('feedback');

	/**
	 * The unique id for the input id
	 * @return {string}
	 */
	textareaId = this.getUniqueId('textarea');

	/**
	 * Returns the feedback
	 *
	 * @param validated {Boolean} Flag is field is validated.
	 * @param failedRules {Object} The object of failed rules.
	 */
	getFeedback(validated, failedRules) {
		let message = null;
		if (validated && failedRules) {
			message = this.validationMessages[Object.keys(failedRules)[0]];
		}
		return message;
	}
	/**
	 * Returns the state
	 *
	 * @param validated {Boolean} Flag indicating if field is validated.
	 * @param invalid {Object} Flag indicating if field is valid
	 */
	getState(validated, invalid) {
		return validated && invalid ? false : null;
	}
	/**
	 * Handle input event.
	 *
	 * @param value {String} Checked value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.label {
	font-family: $josefin-font-family;
}
.invalid-feedback {
	text-align: left;
	font-size: 14px;
	color: $error-red;
	line-height: 17px;
	font-weight: 400;
	background-color: $error-background-pink;
	border-color: $red;
	padding: 10px;
	height: auto;
	border-radius: 5px;
	margin-top: 0;
}
</style>
