<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<div>
		<BRow>
			<BCol class="form">
				<ForgotPasswordForm
					v-model="member"
					:automation-id="'forgot-password'"
					@submit="submitForgotPassword()"
				/>
				<BaseButton
					:label="$t('title.returnLogin')"
					class="mt-1 mx-auto pl-0 returnLoginBtn"
					:pill="false"
					variant="link"
					:automation-id="'forgot-password'"
					@click="toLogin()"
				/>
			</BCol>
		</BRow>
		<LoadingModal :modal-show="isLoading" :modal-text="''" :automation-id="'loading-modal'" />
	</div>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
/**
 * This component is responsible to render registration content
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import { BCol, BRow } from 'bootstrap-vue';
import Authentication from '@/models/Authentication';
import ForgotPasswordForm from '@/components/login/ForgotPasswordForm';
import BaseButton from '@/components/common/base/BaseButton';
import LoadingModal from '@/components/common/LoadingModal';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes.js';

// @vue/component
@Component({
	name: 'ForgotPassword',
	components: {
		BCol,
		BRow,
		ForgotPasswordForm,
		LoadingModal,
		BaseButton
	},
	mixins: [IdMixin]
})
export default class ForgotPassword extends Vue {
	isModalVisible = false;
	error = {
		title: '',
		body: ''
	};
	member = {
		certificateId: '',
		policyId: '',
		email: '',
		selectedOption: null
	};

	get isLoading() {
		return this.$store.state.loading;
	}
	toLogin() {
		this.$router.push({ name: Routes.LOGIN });
	}

	/**
	 * Registration API call
	 */
	async submitForgotPassword() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		if (this.member?.selectedOption === 'email') {
			await this.submitForgotPasswordWEmail();
		} else {
			await this.submitForgotPasswordWPolCert();
		}
		this.$store.dispatch('updateLoading', false);
	}

	async submitForgotPasswordWPolCert() {
		const response = await Authentication.PasswordResetWPolCert(
			this.member,
			this.$store.state.i18n.locale
		);
		if (response.status < 201) {
			this.$router.push({ name: Routes.SUCCESS_RESET_EMAIL });
		} else if (response.status === 400 || response.status === 404) {
			const error = response.data;
			if (response?.data?.message !== undefined) {
				this.$store.dispatch('error', { name: 'submitForgotPasswordWEmail', err: error.message });
			} else {
				this.$store.dispatch('error');
			}
		} else {
			this.$store.dispatch('error');
		}
	}

	async submitForgotPasswordWEmail() {
		const response = await Authentication.PasswordResetWEmail(
			this.member,
			this.$store.state.i18n.locale
		);
		if (response?.status < 500) {
			this.$router.push({ name: Routes.SUCCESS_RESET_EMAIL });
		} else {
			this.$store.dispatch('error');
		}
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
.form {
	margin: auto;
	margin-top: 30px;
	max-width: 550px;
}

* ::v-deep button {
	margin: 0 auto;
}
.returnLoginBtn {
	margin-bottom: 1em;
}
.returnLoginBtn ::v-deep span {
	white-space: normal;
	margin-bottom: 1em;
}
</style>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
{
  "en": {
    "title": {
      "returnLogin": "Cancel, take me back to login"
    }
  },
  "fr": {
    "title": {
      "returnLogin": "Annuler et retourner à la page d’ouverture de session"
    }
  }
}
    </i18n>
