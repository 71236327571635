<template>
	<div>
		<BRow class="row-spacing">
			<BCol cols="12">
				<!-- heading levels changed to avoid accesibility skipped heading levels -->
				<h3 class="h4 pt-3 mb-0">{{ $t('privacyPolicy') }}</h3>
			</BCol></BRow
		>
		<BRow class="row-spacing">
			<BCol cols="12">
				<p class="last-updated mb-0">{{ $t('lastUpdated') }} {{ privacyLastUpdatedDate }}</p>
			</BCol></BRow
		>
		<BRow class="privacy-text-row">
			<BCol cols="12">
				<p class="mb-0">{{ $t('privacyNewTab') }}</p>
			</BCol></BRow
		>
		<BRow class="mb-2">
			<BCol cols="12">
				<BaseButton
					automation-id="privacy-link"
					variant="link"
					:label="$t('label.privacy')"
					:icon="['fal', 'external-link-alt']"
					icon-position="right"
					class="link-button p-0"
					@click="goToPrivacyPolicy()"
				/>
			</BCol>
		</BRow>
		<BRow class="row-spacing">
			<BCol cols="12">
				<h3 class="h4 pt-3 mb-0">{{ $t('termsConUse') }}</h3>
			</BCol>
		</BRow>
		<BRow class="row-spacing">
			<BCol cols="12">
				<p class="last-updated mb-0">{{ $t('lastUpdated') }} {{ termsLastUpdatedDate }}</p>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12">
				<BaseButton
					automation-id="terms-and-conditions-link"
					variant="link"
					:label="$t('label.terms')"
					class="link-button terms-button p-0"
					@click="showTermsModal ? changeVisibility(true) : goToTermsPage()"
				/>
			</BCol>
		</BRow>

		<BaseModal
			:id="getUniqueId('terms-and-conditions')"
			class="legal-modal"
			:modal-show="modalShow"
			:modal-title="$t('termsConUse')"
			is-header-one
			css-header-class="footer-modal"
			:is-static="true"
			:automation-id="'terms-and-conditions'"
			@change="changeVisibility"
		>
			<template #contentBody>
				<slot>
					<!-- eslint-disable-next-line vue/no-v-html -->
					<div v-html="termsAndCondition"></div>
					<div class="button-div">
						<BaseButton
							variant="primary"
							class="thebtn"
							:automation-id="'terms-and-conditions-modal-close-button'"
							:label="$t('modal.close')"
							:aria-label="$t('modal.close')"
							@click="changeVisibility(false)"
						>
						</BaseButton>
					</div>
				</slot>
			</template>
		</BaseModal>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
//Mixins
import IdMixin from '@/mixins/id';
//Models
import MemberCardInfo from '@/models/MemberCardInfo';
//Constants
import * as Routes from '@/constants/Routes.js';
//Components
import BaseButton from '@/components/common/base/BaseButton';
import BaseModal from '@/components/common/base/BaseModal';
import { BRow, BCol } from 'bootstrap-vue';
//Content
import termsConditionsEn from '@/content/legal/terms-conditions-en.js';
import termsConditionsFr from '@/content/legal/terms-conditions-fr.js';
// @vue/component
@Component({
	name: 'TermsAndConditionContentAndPrivacy',
	components: {
		BaseButton,
		BaseModal,
		BRow,
		BCol
	},
	mixins: [IdMixin],
	props: {
		// Prop is used to determine if the terms and conditions button should show a modal
		// or if it should link to the terms and conditions page.
		showTermsModal: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		locale() {
			this.getResourceUrls();
		}
	}
})
export default class TermsAndConditionContentAndPrivacy extends Vue {
	privacyLastUpdatedDate = null;
	termsLastUpdatedDate = null;
	modalShow = false;
	brand = null;

	async created() {
		this.brand = await JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
		await this.getResourceUrls();
	}

	/**
	 * Computed prop to determine which terms and conditions to show based on the members selected locale.
	 */
	get termsAndCondition() {
		if (this.$store.state.i18n.locale === 'fr') return termsConditionsFr;
		return termsConditionsEn;
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}

	/**
	 * Navigate to the external corporate site for the privacy Policy.
	 * Determine which site to go to based on the members brand.
	 * If no brand is found then go to default Medavie site.
	 */
	async goToPrivacyPolicy() {
		if (this.brand && this.brand === 'SBC') {
			window.open('https://prodwww.sk.bluecross.ca/legal/privacy', '_blank');
		} else if (this.brand && this.brand === 'OBC') {
			window.open('https://www.medaviebc.ca/en/ontarioplans/privacy', '_blank');
		} else if (this.brand && this.brand === 'QBC') {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/en/quebecplans/privacy', '_blank');
			} else {
				window.open(
					'https://www.medaviebc.ca/fr/regimesquebec/renseignements-personnels',
					'_blank'
				);
			}
		} else {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/en/legal/privacy', '_blank');
			} else {
				window.open(
					'https://www.medaviebc.ca/fr/mentions-legales/protection-des-renseignements-personnels',
					'_blank'
				);
			}
		}
	}

	/**
	 * Retrieve the resource urls from the API to use the urls to determine the last updated date for the privacy policy and the terms and conditions
	 */
	async getResourceUrls() {
		MemberCardInfo.getResourceUrlsByEmail(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		).then((response) => {
			if (response && (response.status === 201 || response.status === 200)) {
				// Retrieve the updated date for the privacy policy from S3.
				let secureUrl = response.data.json_privacyUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => {
						this.privacyLastUpdatedDate = data.updatedDate;
					})
					.catch((error) => {
						if (error) {
							this.$store.dispatch('error', {
								name: 'generic'
							});
						}
					});
				// Retrieve the updated date for the terms and conditions from S3.
				secureUrl = response.data.json_eulaUrl.replace('http://', 'https://');
				fetch(secureUrl)
					.then((response) => response.json())
					.then((data) => {
						this.termsLastUpdatedDate = data.updatedDate;
					})
					.catch((error) => {
						if (error) {
							this.$store.dispatch('error', {
								name: 'generic'
							});
						}
					});
			}
		});
	}

	/**
	 * Go to the terms and conditions page.
	 */
	goToTermsPage() {
		this.$router.push({ name: Routes.TERMS_AND_CONDITIONS });
	}

	/**
	 * Toggle visibilty of the terms and conditions modal.
	 */
	changeVisibility(isVisible) {
		this.modalShow = isVisible;
	}
}
</script>

<style lang="scss" scoped>
.last-updated {
	font-size: 17px;
	font-weight: 400;
}
.link-button {
	font-family: $font-family-regular;
	font-size: 17px;
	font-weight: 400;
	line-height: 17px;
	margin-left: -1px;
}
.terms-button {
	margin-bottom: 30px;
}
.row-spacing {
	margin-bottom: 7px;
}
.privacy-text-row {
	margin-bottom: 4px;
}
.button-div {
	margin-top: 2.5em;
}
</style>
<i18n>
{
  "en": {
    "termsConUse": "Terms and Conditions of Use",
    "privacyPolicy": "Privacy Policy",
    "privacyNewTab": "The Privacy Policy will open in a new tab or window.",
    "lastUpdated": "Last updated ",
    "label": {
        "privacy": "Read our Privacy Policy",
        "terms": "Read our Terms & Conditions"
    },
    "modal": {
        "close": "Close"
    }
  },
  "fr": {
    "termsConUse": "Conditions d'utilisation",
    "privacyPolicy": "Politique de confidentialité",
    "privacyNewTab": "La Politique de confidentialité s'ouvrira dans un nouvel onglet ou une nouvelle fenêtre.",
    "lastUpdated": "Dernière mise à jour en ",
    "label": {
        "privacy": "Lire notre Politique de confidentialité",
        "terms": "Lire nos Conditions d'utilisation"
    },
    "modal": {
        "close": "Fermer"
    }
  }
}
</i18n>
