<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template data-tracking-id="policy-id-input">
	<BaseMaskedInput
		:value="value"
		validation-id="policy_id_validation"
		type="tel"
		mask="##########"
		data-vv-validate-on="blur"
		:automation-id="getAutomationId('policyid')"
		:validation-rules="{ required: true, min: 10 }"
		:validation-messages="{
			required: $t('error.policyEmpty'),
			min: $t('error.policyShort')
		}"
		:label="$t('title.policyId')"
		@input="updatePolicy"
		@blur="onBlur"
	>
		<template #infoButton>
			<InsuranceCardModal :automation-id="getAutomationId('policyid')" />
		</template>
	</BaseMaskedInput>
</template>
<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput';
import InsuranceCardModal from '@/components/common/InsuranceCardModal';
import IdMixin from '@/mixins/id.js';
import { required, min } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('min', min);
// @vue/component
@Component({
	name: 'PolicyIdInput',
	components: {
		BaseMaskedInput,
		InsuranceCardModal
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class PolicyIdInput extends Vue {
	data() {
		return {
			savedMaskedValue: null
		};
	}

	/**
	 * Returns the mask value.
	 */
	get maskedValue() {
		return this.value;
	}
	/**
	 * Handle blur event.
	 */
	onBlur() {
		const policyId = this.savedMaskedValue;
		this.$emit('blur', policyId);
	}

	updatePolicy(policyId) {
		this.savedMaskedValue = policyId;
		this.$emit('input', policyId);
	}
}
</script>
<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
    {
    "en": {
		"title": {
    		"policyId": "Policy number"
		},
     "error": {
         	"policyEmpty": "Please enter your policy number.",
			 "policyShort": "Your policy number is too short. Please confirm and try again."
	  }
	 },
	 "fr": {
		"title": {
			"policyId": "Numéro de police"
		},
		"error": {
			"policyEmpty": "Veuillez entrez votre numéro de police.",
			"policyShort": "Votre numéro de police est trop court. Veuillez vérifier et essayer de nouveau."
	  }
	 }
	}
</i18n>
