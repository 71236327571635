<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseMaskedInput
		:value="value"
		validation-id="member_cert_id_validation"
		type="text"
		mask="XXXXXXXXXXX"
		data-vv-validate-on="blur"
		:automation-id="getAutomationId('certid')"
		:validation-rules="{ required: true, min: 11 }"
		:validation-messages="{
			required: $t('error.cardEmpty'),
			min: $t('error.cardToShort')
		}"
		:label="$t('title.cardId')"
		@blur="onBlur"
		@input="updateCardId"
	>
		<template #infoButton>
			<InsuranceCardModal class="insurance-card-modal" :automation-id="getAutomationId('certid')" />
		</template>
	</BaseMaskedInput>
</template>
<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseMaskedInput from '@/components/common/base/BaseMaskedInput';
import InsuranceCardModal from '@/components/common/InsuranceCardModal';
import IdMixin from '@/mixins/id.js';
import { required, min, alpha_num } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
extend('min', min);
extend('alpha_num', alpha_num);
// @vue/component
@Component({
	name: 'CertificateIdInput',
	components: {
		BaseMaskedInput,
		InsuranceCardModal
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: String,
			default: ''
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class CertificateIdInput extends Vue {
	data() {
		return {
			savedMaskedValue: null
		};
	}

	/**
	 * Returns the mask value.
	 */
	get maskedValue() {
		return this.value;
	}

	/**
	 * Handle blur event.
	 */
	onBlur() {
		const cardId = this.savedMaskedValue;
		this.$emit('blur', cardId);
	}

	updateCardId(cardId) {
		this.savedMaskedValue = cardId;
		this.$emit('input', cardId);
	}
}
</script>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->
<i18n>
    {
    "en": {
		"title": {
    		"cardId": "ID number"
		},
     "error": {
       "cardEmpty": "Please enter your ID number.",
			 "cardToShort": "Your ID number is too short. Please confirm and try again."
	  }
	 },
	 "fr": {
		"title": {
			"cardId": "Numéro d'identification"
		},
		"error": {
			"cardEmpty": "Entrez votre numéro d’identification.",
			"cardToShort": "Votre numéro d’identification est trop court. Veuillez vérifier et essayer de nouveau."
	  }
	 }
	}
</i18n>
