<template>
	<div class="expired-token">
		<Result
			icon="alarm-clock"
			icon-type="fal"
			icon-color="error-color"
			:title="$t('title')"
			:hide-button="true"
			:content="$t('primaryContent')"
			:secondary-content="$t('secondaryContent')"
			button-variant="primary"
			:automation-id="getAutomationId('expired-link')"
		>
			<BaseButton
				class="button"
				:label="$t('button')"
				variant="primary"
				:automation-id="getAutomationId('expired-link')"
				:data-tracking-id="getAutomationId('expired-link')"
				type="submit"
				@click="resendLink()"
			/>
		</Result>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id';
import Authentication from '@/models/Authentication';
import { SUCCESS_RESET_LOGIN } from '@/constants/Routes.js';

// @vue/component
@Component({
	name: 'ExpiredToken',
	components: {
		Result,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class ExpiredToken extends Vue {
	async resendLink() {
		this.$store.dispatch('updateLoading', true);
		let response = await Authentication.renewToken(
			this.$route.params.token,
			this.$route.params.locale
		);

		if (response && response.status === 200) {
			this.$store.dispatch('clearError');
			this.$router.push({ name: SUCCESS_RESET_LOGIN });
		} else {
			if (response?.data?.message) {
				this.$store.dispatch('error', { name: 'changePassword', err: response?.data?.message });
			} else {
				this.$store.dispatch('error', { name: 'generic', err: 'generic' });
			}
		}
		this.$store.dispatch('updateLoading', false);
	}
}
</script>
<i18n>
{
    "en": {
        "title": "Sorry! This link has expired.",
        "primaryContent": "Links are only valid for 24 hours.",
		"secondaryContent": "Click the button below and we'll send you a new one.",
        "button": "Resend link"
    },
    "fr": {
        "title": "Désolés! Ce lien est expiré.",
        "primaryContent": "Les liens sont valides seulement pendant 24 heures.",
		"secondaryContent": "Cliquez sur le bouton ci-dessous et nous vous en enverrons un nouveau.",
        "button": "Renvoyer le lien"
    }
}
</i18n>
