<template>
	<Result
		icon="exclamation-triangle"
		icon-type="fal"
		icon-color="error-color"
		:title="$t('title')"
		:sub-title="$t('tryAgain')"
		:hide-button="false"
		:has-body="false"
		button-variant="primary"
		:button="$t('toLogin')"
		class="text-center"
		:automation-id="getAutomationId('error-token-validation')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import Result from '@/components/result/Result';

@Component({
	name: 'ErrorTokenValidation',
	mixins: [IdMixin],
	components: {
		Result
	}
})
export default class ErrorTokenValidation extends Vue {}
</script>

<i18n>
{
  "en": {
    "title": "Oops, something went wrong.",
    "tryAgain": "Please try again.",
    "toLogin": "Go to login >"
  },
  "fr": {
    "title": "Nous sommes désolés, une erreur s’est produite.",
    "tryAgain": "Veuillez réessayer.",
    "toLogin": "Aller à la page d’ouverture de session >"
  }
}
</i18n>
