export default `
<ol>
    <li class="mb-4 pl-3">
        The detail available herein has been prepared for information purposes only and does not constitute an agreement
        or contract between you and Blue Cross.
        The information provided summarizes the important features of your benefit plan and should not be relied upon as
        the authoritative text.
        The terms and conditions of your benefits program, including exclusions, limitations and definitions, are
        described in your Individual Policy or the Master Group Contract held by your benefits administrator.
        In the event of a discrepancy between these and the information contained on this web site, the former shall
        prevail.
    </li>
    <li class="mb-4 pl-3">The information provided is based upon data currently in our files and is believed to be reliable.
        We do not guarantee that the information will be accurate, complete and current at all times as it may be
        subject to modification from time to time. Blue Cross is not liable or responsible for damages of any kind
		arising out of the use of the information contained herein.
	</li>
    <li class="mb-4 pl-3">
        The Canadian Dental Association is the copyright owner of the Uniform System of Dental Codes and List of
        Services, and the Denturist Association of Canada is the copyright owner of the Denturist Codes and List of
        Services.
    </li>
</ol>
`;
