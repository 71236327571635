<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->
<template>
	<BaseAccordion class="accordion" :automation-id="getAutomationId('forgot-password-form')">
		<BaseAccordionPanel
			group-name="faq"
			:button-label="$t('cantRememberPassword.headerText')"
			:automation-id="getAutomationId('forgot-password')"
			border
		>
			<p class="description">{{ $t('cantRememberPassword.description') }}</p>
			<BaseButton
				:label="$t('button.reset')"
				class="button"
				variant="primary"
				:automation-id="getAutomationId('forgot-password')"
				@click="toForgotPassword"
			/>
		</BaseAccordionPanel>
		<BaseAccordionPanel
			group-name="faq"
			:button-label="$t('cantRememberEmail.headerText')"
			:automation-id="getAutomationId('forgot-email')"
			border
		>
			<p class="description">{{ $t('cantRememberEmail.description') }}</p>
			<BaseButton
				:label="$t('button.contactUs')"
				class="button"
				variant="primary"
				border
				:automation-id="getAutomationId('contact-us')"
				@click="scrollToCallUs"
			/>
		</BaseAccordionPanel>
		<BaseAccordionPanel
			group-name="faq"
			:button-label="$t('haveTroubleLogging.headerText')"
			:automation-id="getAutomationId('trouble-log-in')"
			border
		>
			<p class="description">{{ $t('haveTroubleLogging.description') }}</p>
			<BaseButton
				:label="$t('button.resetAccount')"
				class="button"
				variant="primary"
				:automation-id="getAutomationId('reset-account')"
				@click="toResetAccount"
			/>
			<BaseButton
				:label="$t('button.reset')"
				class="second-button"
				variant="primary"
				:automation-id="getAutomationId('reset-password')"
				@click="toForgotPassword"
			/>
		</BaseAccordionPanel>
		<BaseAccordionPanel
			group-name="faq"
			:button-label="$t('newUser.headerText')"
			:automation-id="getAutomationId('new-user')"
			border
		>
			<p class="description">{{ $t('newUser.description') }}</p>
			<BaseButton
				:label="$t('button.registerUser')"
				class="button"
				variant="primary"
				:automation-id="getAutomationId('register')"
				@click="toRegistration"
			/>
		</BaseAccordionPanel>
		<BaseAccordionPanel
			group-name="faq"
			:button-label="$t('lostMemberCard.headerText')"
			:automation-id="getAutomationId('lost-member-card')"
		>
			<p class="p3-0 description">{{ $t('lostMemberCard.description') }}</p>
			<BaseButton
				:label="$t('button.callUs')"
				class="button"
				variant="primary"
				:automation-id="getAutomationId('call-us')"
				@click="scrollToCallUs"
			/>
		</BaseAccordionPanel>
	</BaseAccordion>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
/**
 * This component renders a button that toggles contact information
 */
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id.js';
import * as Routes from '@/constants/Routes.js';
import BaseAccordion from '@/components/common/base/BaseAccordion';
import BaseAccordionPanel from '@/components/common/base/BaseAccordionPanel';

// @vue/component
@Component({
	name: 'NeedHelpCard',
	components: {
		BaseButton,
		BaseAccordionPanel,
		BaseAccordion
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class NeedHelpCard extends Vue {
	displayCantRememberPassword = false;
	displayCantRememberEmail = false;
	displayHaveTroubleLogging = false;
	displayNewUser = false;
	displayLostMemberCard = false;

	data() {
		return {
			iconDown: ['fal', 'angle-down'],
			iconUp: ['fal', 'angle-up']
		};
	}

	toForgotPassword() {
		this.$router.push({ name: Routes.FORGOT_PASSWORD });
	}

	toResetAccount() {
		this.$router.push({ name: Routes.RESET_YOUR_ACCOUNT });
	}

	toRegistration() {
		this.$router.push({ name: Routes.REGISTRATION });
	}

	scrollToCallUs() {
		const { $el } = this.$parent.$refs.contact;
		if ($el) {
			$el.scrollIntoView({ behavior: 'smooth' });
		}
	}
}
</script>

<!-- *************************************************************************
	Styles
	************************************************************************* -->
<style lang="scss" scoped>
::v-deep p {
	margin-bottom: 0;
}
.button {
	margin-bottom: 1em;
	margin-top: 1em;
}
.second-button {
	margin-left: 0.5rem;
	margin-bottom: 1em;
	margin-top: 1em;
	transition: none;
	@include media-breakpoint-down(sm) {
		display: block;
		margin-left: 0px;
		margin-top: 0px;
	}
}
.accordion ::v-deep .accordion-content {
	padding: 0 15px;
}
</style>

<i18n>
{
  "en": {
    "cantRememberPassword": {
      "headerText": "I can't remember my password.",
      "description": "To reset your password, you’ll need your ID and policy number (which you can find on your member card). Use the button below to fill in your information, and then we’ll send you an email with instructions on how to finish resetting your password."
    },
    "cantRememberEmail": {
      "headerText": "I can’t remember the email I used to register or have no access to that email account anymore.",
      "description": "If you’ve forgotten the email you used to create your account or lost access to the email account, we’re here to help. To regain access to your account, get in touch with our customer services representatives so they can help you out."
    },
    "haveTroubleLogging": {
      "headerText": "I’m having trouble logging in.",
      "description": "If you’re having trouble logging in, try resetting your password or—if that fails—resetting your account. Don’t worry. Resetting your account is simple. You’ll just need your ID number and policy number from your member card."
    },
    "newUser": {
      "headerText": "I’m a new user. How do I set up my account?",
      "description": "Welcome! We’re happy to have you. Setting up your new account is fast and straightforward. Have your member card with your ID and policy number ready, and then use the button below to set up your account today."
    },
    "lostMemberCard": {
      "headerText": "I lost my member card; how can I find my policy and ID number?",
      "description": "Misplaced your member card? We can help with that. Get in touch with our customer service representatives in your area, and we’ll make sure you get the information you need."
    },
    "button": {
      "reset": "Reset password",
      "contactUs": "Contact Us",
      "resetAccount": "Reset account",
      "registerUser": "Register",
      "callUs": "Call us"
    }
  },
  "fr": {
    "cantRememberPassword": {
      "headerText": "J’ai oublié mon mot de passe.",
      "description": "Pour réinitialiser votre mot de passe, vous aurez besoin de votre numéro d’identification et de votre numéro de police (qui se trouvent sur votre carte d’adhérent). Appuyez sur le bouton ci-dessous pour entrer vos renseignements et nous vous enverrons un courriel contenant les directives pour terminer la réinitialisation de votre mot de passe."
    },
    "cantRememberEmail": {
      "headerText": "Je ne me souviens plus de l’adresse courriel que j’ai utilisée pour m’inscrire ou je n’ai plus accès à ce compte de courriel.",
      "description": "Si vous avez oublié l’adresse courriel que vous avez utilisée pour créer votre compte ou si vous n’avez plus accès au compte de courriel qui y est lié, nous pouvons vous aider. Pour accéder à nouveau à votre compte, communiquez avec notre Service à la clientèle, un membre de notre équipe sera heureux de vous aider."
    },
    "haveTroubleLogging": {
      "headerText": "J’ai de la difficulté à ouvrir une session.",
      "description": "Si vous avez de la difficulté à ouvrir une session, essayez de réinitialiser votre mot de passe en premier et si vous éprouvez toujours des problèmes, réinitialisez votre compte. Ne vous en faites pas, la réinitialisation de compte est simple. Vous n’avez besoin que du numéro d’identification et du numéro de police qui figurent sur votre carte d’adhérent."
    },
    "newUser": {
      "headerText": "Je souhaite m’inscrire, comment puis-je créer un compte?",
      "description": "Bienvenue! Nous sommes heureux de vous accueillir. Il est rapide et simple de créer un nouveau compte. Ayez votre carte d’adhérent avec votre numéro d’identification et votre numéro de police en main et appuyez sur le bouton ci-dessous pour créer votre compte immédiatement."
    },
    "lostMemberCard": {
      "headerText": "J’ai perdu ma carte d’adhérent; où puis-je trouver mon numéro d’identification et mon numéro de police?",
      "description": "Vous avez perdu votre carte d’adhérent? Nous pouvons vous aider. Communiquez avec notre équipe du Service à la clientèle de votre région et elle vous donnera tous les renseignements dont vous avez besoin."
    },
    "button": {
      "reset": "Réinitialiser mon mot de passe",
      "contactUs": "Communiquez avec nous",
      "resetAccount": "Réinitialiser mon compte",
      "registerUser": "S’inscrire",
      "callUs": "Téléphonez-nous"
    }
  }
}
</i18n>
