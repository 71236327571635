<template>
	<div class="mainCard">
		<div :class="isEnglish ? 'content-en' : 'content-fr'">
			<template v-if="showPreviousPasswordError">
				<BaseAlert variant="danger" :icon="['fal', 'exclamation-triangle']">
					{{ previousPasswordError }}
				</BaseAlert>
			</template>
			<PasswordInput
				:vid="'register_password'"
				:label="$t('password')"
				class="p-1"
				:value="member.password"
				:automation-id="getAutomationId('change-password-form')"
				@input="updatePassword"
			/>
			<ConfirmInput
				:label="$t('confirm')"
				:field="$t('password')"
				class="p-1"
				:class="!isEnglish ? 'confirm-input-fr' : 'confirm-input-en'"
				type="password"
				:confirmation="member.password"
				:value="member.confirm"
				:password="true"
				:automation-id="getAutomationId('change-password-form')"
				@input="updateConfirm"
			/>
		</div>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import PasswordInput from '@/components/common/PasswordInput';
import ConfirmInput from '@/components/common/ConfirmInput';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import IdMixin from '@/mixins/id';
// @vue/component
// This is the change password form for the Forgot Password flow.
@Component({
	name: 'ChangePasswordForm',
	components: {
		PasswordInput,
		ConfirmInput,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},

		automationId: {
			type: String,
			default: ''
		},
		previousPasswordError: {
			type: String,
			default: null
		},
		showPreviousPasswordError: {
			type: Boolean,
			default: false
		}
	}
})
export default class ChangePasswordForm extends Vue {
	get member() {
		return this.value ? this.value : {};
	}

	/**
	 * computed property to get the local to determine what image to display.
	 */
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	updatePassword(password) {
		this.$emit('input', { ...this.value, password });
	}

	updateConfirm(confirm) {
		this.$emit('input', { ...this.value, confirm });
	}
}
</script>

<style lang="scss" scoped>
.header {
	background: $blue;
	color: $white;
}
.mainCard {
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 10px;
	opacity: 1;
	width: 100%;
	margin: 0 auto;
	border: none;
}
.content-en {
	width: 70%;
	background: #ffffff 0% 0% no-repeat padding-box;
	@media (max-width: 576px) {
		width: 100%;
	}
}
.content-en ::v-deep label {
	@media (max-width: 320px) {
		width: 64%;
	}
}

.content-fr ::v-deep .btn {
	@media (max-width: 320px) {
		margin-top: 23px;
	}
}

.content-fr {
	width: 80%;
	background: #ffffff 0% 0% no-repeat padding-box;
	@media (max-width: 576px) {
		width: 100%;
	}
}

.confirm-input-fr ::v-deep label {
	@media (max-width: 576px) {
		width: 69%;
	}
}

.content-fr ::v-deep label {
	@media (max-width: 320px) {
		width: 64%;
	}
}

.confirm-input-fr ::v-deep .btn {
	@media (max-width: 576px) {
		margin-top: 23px;
	}
	@media (max-width: 320px) {
		margin-top: 48px;
	}
}
.confirm-input-en ::v-deep .btn {
	@media (max-width: 320px) {
		margin-top: 23px;
	}
}
</style>

<i18n>
{
  "en": {
    "password": "New password",
    "confirm": "Confirm new password"
  },
  "fr": {
    "password": "Nouveau mot de passe",
    "confirm": "Confirmez le nouveau mot de passe"
  }
}
</i18n>
