<template>
	<BaseCard
		id="forgotPasswordCard"
		class="base-card"
		:automation-id="getAutomationId('forgot-password-form')"
	>
		<BaseForm
			ref="authentication-form"
			:data-tracking-id="getAutomationId('forgot-password-form')"
			:automation-id="getAutomationId('forgot-password-form')"
			@submit="onSubmit"
		>
			<h1>{{ $t('title.reset') }}</h1>
			<p class="text">{{ $t('paragraph') }}</p>
			<div class="bottomBorder"></div>
			<BaseAlert
				v-if="hasNoSelection"
				variant="danger"
				:icon="['fal', 'exclamation-triangle']"
				class="error-body mt-3"
			>
				{{ $t('error') }}
			</BaseAlert>
			<BaseRadioButtonGroup
				:label="$t('label.radio')"
				:value="member.selectedOption"
				:options="resetOptions"
				stacked
				class="mt-3"
				:automation-id="getAutomationId('forgot-password-form')"
				@input="updateSelectedOption"
			/>
			<div v-if="isPolicyAndCertOption" class="inputs">
				<CertificateIdInput
					:value="member.certificateId"
					class="mt-3"
					:automation-id="getAutomationId('forgot-password-form')"
					@input="updateCertId"
				/>
				<PolicyIdInput
					:value="member.policyId"
					class="mt-3"
					:automation-id="getAutomationId('forgot-password-form')"
					@input="updatePolicyId"
				/>
			</div>
			<div v-if="isEmailOption" class="inputs">
				<EmailInput
					:label="$t('email')"
					:value="member.email"
					:automation-id="getAutomationId('forgot-password-form')"
					@input="updateEmail"
				/>
			</div>
			<BaseButton
				:label="$t('button.reset')"
				class="reset-button"
				variant="primary"
				:automation-id="getAutomationId('forgot-password-form')"
				type="submit"
			/>
		</BaseForm>
	</BaseCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';
import BaseForm from '@/components/common/base/BaseForm';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import CertificateIdInput from '@/components/common/CertificateIdInput';
import PolicyIdInput from '@/components/common/PolicyIdInput';
import BaseButton from '@/components/common/base/BaseButton';
import BaseRadioButtonGroup from '@/components/common/base/BaseRadioButtonGroup';
import EmailInput from '@/components/common/EmailInput';

// @vue/component
@Component({
	name: 'ForgotPasswordForm',
	components: {
		BaseForm,
		CertificateIdInput,
		PolicyIdInput,
		BaseButton,
		BaseCard,
		BaseRadioButtonGroup,
		EmailInput,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			required: true
		}
	}
})
export default class ForgotPasswordForm extends Vue {
	hasNoSelection = false;

	get resetOptions() {
		return [
			{ text: this.$t('options.polCert'), value: 'pol/certs' },
			{ text: this.$t('options.email'), value: 'email' }
		];
	}

	get member() {
		return this.value ? this.value : {};
	}

	get isPolicyAndCertOption() {
		return this.member?.selectedOption === 'pol/certs' ? true : false;
	}

	get isEmailOption() {
		return this.member?.selectedOption === 'email' ? true : false;
	}

	updateCertId(certificateId) {
		this.$emit('input', { ...this.value, certificateId });
	}

	updatePolicyId(policyId) {
		this.$emit('input', { ...this.value, policyId });
	}

	updateEmail(email) {
		this.$emit('input', { ...this.value, email });
	}

	updateSelectedOption(selectedOption) {
		this.hasNoSelection = false;
		this.$emit('input', { ...this.value, selectedOption });
	}

	onSubmit() {
		if (!this.isEmailOption && !this.isPolicyAndCertOption) {
			this.hasNoSelection = true;
		} else {
			this.$emit('submit');
		}
	}
}
</script>
<style lang="scss" scoped>
.text {
	font-size: 21px;
	color: $black-text;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	padding-right: 0.2rem;
}
.bottomBorder {
	width: 100%;
	border-bottom: 1px solid $light-grey;
}
* ::v-deep .contentbody {
	width: 100%;
	border: 0;
}
.resetButton {
	max-height: 50px;
	padding: 0 15px;
}
.inputs {
	max-width: 300px;
}
.base-card {
	margin-bottom: 0px;
}
.error-body {
	width: 100%;
}
.card ::v-deep img {
	object-position: center;
	@include media-breakpoint-down(xs) {
		height: 120px;
	}
}
</style>
<i18n>
{
  "en": {
    "title": {
      "reset": "Reset your password"
    },
    "paragraph": "Enter your information and we'll email you a link to reset your password.",
    "button": {
      "reset": "Reset password"
		},
		"email": "Email address",
		"label": {
			"radio": "I want to reset my password by providing my"
		},
		"options": {
			"email": "email address",
			"polCert": "ID number and policy number"
		},
		"error" : "Please select an option to reset your password."
  },
  "fr": {
    "title": {
      "reset": "Réinitialiser le mot de passe"
    },
    "paragraph": "Entrez vos informations et nous vous enverrons un courriel avec un lien pour réinitialiser votre mot de passe.",
    "button": {
      "reset": "Réinitialiser mon mot de passe"
		},
		"email": "Adresse courriel",
		"label": {
			"radio": "Je souhaite réinitialiser mon mot de passe en fournissant mon :"
		},
		"options": {
			"email": "adresse courriel",
			"polCert": "numéro d’identification et numéro de police"
		},
		"error" : "Veuillez sélectionner une façon de réinitialiser votre mot de passe."
  }
}
</i18n>
