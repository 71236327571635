<template>
	<BContainer>
		<TermsAndConditionForm
			:automation-id="getAutomationId('terms-conditions')"
			@response="hasReplied"
		/>

		<ErrorModal
			:data-test-automation-id="getAutomationId('terms-conditions')"
			:automation-id="getAutomationId('terms-conditions')"
			:modal-show="hasDeclined"
			:has-close-button="false"
			no-hide-on-blur
			can-close-on-esc
			class="p-2"
			:error-title="$t('decline.title')"
			:error-message="$t('decline.body.part1') + '<br /><br />' + $t('decline.body.part2')"
		>
			<BaseButton
				:label="$t('button.cancel')"
				class="tac-form-page-buttons mr-2"
				variant="primary"
				:automation-id="getAutomationId('terms-conditions-cancel')"
				@click="closeModal"
			/>
			<BaseButton
				:label="$t('button.decline')"
				class="tac-form-page-buttons"
				:automation-id="getAutomationId('terms-conditions-declined')"
				@click="declined"
			/>
		</ErrorModal>
	</BContainer>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BContainer } from 'bootstrap-vue';
import TermsAndConditionForm from '@/components/termsAndConditions/TermsAndConditionForm';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import * as Routes from '@/constants/Routes.js';
import TermsConditions from '@/models/TermsCondition';

// @vue/component
@Component({
	name: 'TermsAndCondition',
	components: {
		BContainer,
		TermsAndConditionForm,
		ErrorModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		user: {
			type: Object,
			default: () => {}
		}
	}
})
export default class TermsAndCondition extends Vue {
	hasDeclined = false;

	get locale() {
		return this.$root.$i18n.locale;
	}

	async hasReplied(response) {
		if (response) {
			this.hasDeclined = false;
			let confirmation = await TermsConditions.confirmTermAndCon(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.locale
			);
			if (confirmation && confirmation.status <= 201) {
				this.$store.dispatch('UpdateLogin', true);
				sessionStorage.setItem('termsAccepted', true);
				if (this.$router.currentRoute.params?.page) {
					this.$router.push({ name: this.$router.currentRoute.params.page });
				} else {
					this.$router.push({ name: Routes.DASHBOARD });
				}
			} else {
				this.$emit('error', {
					header: this.$t('error.wentWrongHeader'),
					body: this.$t('error.wentWrong')
				});
			}
		} else {
			this.hasDeclined = true;
		}
	}

	declined() {
		this.$router.push({ name: Routes.LOGIN });
	}
	change(isVisible) {
		if (!isVisible) isVisible = false;
		this.hasDeclined = isVisible;
	}
	closeModal() {
		this.hasDeclined = false;
	}
}
</script>
<style lang="scss" scoped>
.small-body {
	font-family: $body-small-font-family;
	font-weight: $body-small-font-weight;
	font-size: $body-small-font-size;
}
* ::v-deep .tac-form-page-buttons {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: 5px;
}
</style>
<i18n>
{
    "en": {
		"decline": {
			"title": "Are you sure?",
			"body": {
				"part1": "If you decline we won't be able to grant you access to the member services site.",
				"part2": "Do you want to go back and accept the Privacy Policy and Terms & Conditions?"
			}
		},
		"button": {
			"decline": "No, I decline",
			"cancel": "Yes, take me back"
		},
		"error": {
			"wentWrongHeader": "Something went Wrong",
			"wentWrong": "Oops, something went wrong. Please try again later."
		}
    },
    "fr": {
		"decline" : {
			"title": "Êtes-vous certain(e)?",
			"body": {
				"part1":"En refusant, vous n'aurez pas accès au site Web des adhérents.",
				"part2":"Souhaitez-vous retourner en arrière et accepter notre Politique de confidentialité et nos Conditions d'utilisation?"
			}
		},
		"button": {
			"decline": "Non, je refuse",
			"cancel": "Oui, je veux retourner en arrière"
		},
		"error": {
			"wentWrongHeader": "Une erreur s’est produite",
			"wentWrong": "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard."
		}
	}
}
</i18n>
