<template>
	<Result
		class="invalid-token"
		:icon="'exclamation-circle'"
		icon-type="fal"
		icon-color="error-color"
		:title="$t('title')"
		:content-centered="$t('primaryContent')"
		:out-of-card-content="$t('outOfCardContent')"
		:button="$t('btn')"
		:automation-id="getAutomationId('invalidToken')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'InvalidToken',
	components: {
		Result
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class InvalidToken extends Vue {}
</script>
<style lang="scss" scoped>
* ::v-deep button {
	margin-top: 5px;
	width: 100%;
	@media screen and (max-width: 576px) {
		padding: 0;
	}
}
* ::v-deep .iconSize {
	height: 60px;
	width: 60px;
}
* ::v-deep p {
	margin: 0;
}
.invalid-token ::v-deep .base-card {
	background-color: transparent;
	border: none;
	margin-bottom: 15px;
}
.invalid-token ::v-deep .card-body {
	padding: 0;
}
.invalid-token ::v-deep h1 {
	margin-bottom: 0 !important;
}
.invalid-token ::v-deep .content-centered {
	font-family: $font-family-headline;
	font-size: 30px;
}
.invalid-token ::v-deep .button-label {
	text-align: center;
}
</style>
<i18n>
{
    "en": {
        "title": "Sorry!",
        "primaryContent": "This link is invalid.",
        "outOfCardContent": "Want to access your account?",
        "btn": "Go to login >"
    },
    "fr": {
        "title": "Désolés!",
        "primaryContent": "Ce lien n’est pas valide.",
        "outOfCardContent": "Vous voulez accéder à votre compte?",
        "btn": "Allez à la page d’ouverture de session >"
    }
}
</i18n>
