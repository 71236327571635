<template>
	<Result
		:automation-id="getAutomationId('success-registration')"
		:title="$t('title')"
		:sub-title="$t('subTitle')"
		:content="$t('content.one')"
		:secondary-content="$t('content.two')"
		:button="$t('button')"
		icon-color="success-color"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'SuccessPasswordResetEmail',
	components: {
		Result
	},
	mixins: [IdMixin],
	props: {
		secondaryTitle: {
			type: String,
			default: null
		},
		content: {
			type: String,
			default: null
		},
		iconColor: {
			type: String,
			default: 'white'
		}
	}
})
export default class SuccessPasswordResetEmail extends Vue {
	toLogin() {
		this.$router.push({ name: Routes.LOGIN });
	}

	get contentComp() {
		return this.content ? this.content : this.$t('content');
	}
}
</script>
<style lang="scss" scoped>
.maxWidth {
	max-width: 700px;
	margin: 0 auto;
}
.title {
	font-size: 32px;
	color: $black-text;
}
.secondaryTitle {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
	padding-bottom: 20px;
	line-height: 35px;
}
.iconSize {
	height: 42px;
	width: 42px;
}
.content {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
}
svg path {
	color: $success-icon-green;
}

.marginAuto {
	margin: 0 auto;
}

.mainCard {
	height: 105px;
	max-width: 700px;
	margin: 0 auto;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e9e9e9;
	border-radius: 10px;
	opacity: 1;
}
</style>
<i18n>
{
  "en": {
		"title": "You're almost done!",
		"subTitle": "If your information matches an account on file,<br /> your password reset link has been sent.",
    "content":{
		"one": "Please <b>check your email</b> and <b>click on the link provided</b> to set your new password.",
		"two": "If you don't see the email within 15 minutes, please try again and make sure you use the correct information for your Blue Cross account."
	},
	"button": "Go to login >"
  },
  "fr": {
		"title": "Vous y êtes presque!",
		"subTitle": "Si les renseignements fournis correspondent à un compte existant,<br /> un lien vous permettant de réinitialiser votre mot de passe vous a été envoyé.",
     "content":{
		"one": "<b>Ouvrez le courriel</b> et <b>cliquez sur le lien fourni</b> pour créer votre nouveau mot de passe.",
		"two": "Si vous ne recevez pas le courriel dans un délai de 15 minutes, veuillez recommencer le processus en vous assurant d’utiliser les renseignements associés à votre compte Croix Bleue."
	},
	"button": "Aller à la page de connexion >"
  }
}
</i18n>
