<template>
	<Result
		:automation-id="getAutomationId('success-registration')"
		:sub-title="$t('title')"
		:content="$t('content')"
		icon-color="success-color"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Result from '@/components/result/Result.vue';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes';

// @vue/component
@Component({
	name: 'SuccessPasswordReset',
	components: {
		Result
	},
	mixins: [IdMixin],
	props: {
		secondaryTitle: {
			type: String,
			default: null
		},
		content: {
			type: String,
			default: null
		},
		iconColor: {
			type: String,
			default: 'white'
		}
	}
})
export default class SuccessPasswordReset extends Vue {
	toLogin() {
		this.$router.push({ name: Routes.LOGIN });
	}

	/**
	 * These computed props check if any values were was passed in,
	 * if it wasn't then it used a default values.
	 */
	get secondaryTitleComp() {
		return this.secondaryTitle ? this.secondaryTitle : this.$t('title');
	}

	get contentComp() {
		return this.content ? this.content : this.$t('content');
	}
}
</script>
<style lang="scss" scoped>
.maxWidth {
	max-width: 700px;
	margin: 0 auto;
}
.title {
	font-size: 32px;
	color: $black-text;
}
.secondaryTitle {
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	font-size: 21px;
	padding-bottom: 20px;
	line-height: 35px;
}
.iconSize {
	height: 42px;
	width: 42px;
}
.content {
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 300;
}
svg path {
	color: $success-icon-green;
}

.marginAuto {
	margin: 0 auto;
}

.mainCard {
	height: 105px;
	max-width: 700px;
	margin: 0 auto;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #e9e9e9;
	border-radius: 10px;
	opacity: 1;
}
</style>
<i18n>
{
    "en": {
        "title": "Your password has been successfully changed.",
        "content": "You can now login with your new password."
    },
	"fr": {
        "title": "Votre mot de passe a bien été changé.",
        "content": "Vous pouvez maintenant ouvrir une session avec votre nouveau mot de passe."
    }
}
</i18n>
