window['adrum-start-time'] = new Date().getTime();
(function (config) {
	config.appKey = window.ENV.APP_DYNAMICS_KEY;
	config.adrumExtUrlHttps = 'https://monitoring.medavie.ca';
	config.beaconUrlHttps = 'https://appd-eum.medavie.ca';
	config.beaconUrlHttp = 'https://appd-eum.medavie.ca';
	config.xd = { enable: false };
	config.spa = { spa2: true };
	var f = document.getElementsByTagName('script')[0],
		j = document.createElement('script');
	j.async = true;
	j.src = 'https://monitoring.medavie.ca/adrum/adrum.js';
	f.parentNode.insertBefore(j, f);
})(window['adrum-config'] || (window['adrum-config'] = {}));
