<template>
	<Result
		:title="$t('title')"
		:content="$t('content')"
		:button="$t('btn')"
		:automation-id="getAutomationId('change-email-result')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import Result from '@/components/result/Result';

// @vue/component
@Component({
	name: 'ChangeEmailResult',
	components: {
		Result
	},
	mixins: [IdMixin]
})
export default class ChangeEmailResult extends Vue {}
</script>
<i18n>
{
  "en": {
    "title": "Your email has been successfully changed.",
    "content": "<b>You can now log in with your new email.</b>",
    "btn": "Return to login"
  },
  "fr": {
    "title": "Votre adresse courriel a bien été changée.",
    "content": "<b>Vous pouvez maintenant ouvrir une session avec votre nouvelle adresse courriel.</b>",
    "btn": "Aller à la page de connexion"
  }
}
</i18n>
