<template>
	<BContainer>
		<FormHeader :title="$t('title')" :automation-id="getAutomationId('form-titles')" />
		<BaseCard
			:title="$t('secureSite')"
			:automation-id="getAutomationId('terms-condition')"
			title-tag="h2"
		>
			<BCardText class="small-width mb-0">
				{{ $t('paragraph.pleaseRead') }}
			</BCardText>
			<TermsAndConditionContentAndPrivacy :show-terms-modal="true" />
			<BaseForm
				ref="timeout-form"
				:data-tracking-id="getAutomationId('timeout-form')"
				:automation-id="getAutomationId('timeout-form')"
				@keydown.enter="submit(true)"
				@submit="submit(true)"
			>
				<BaseCheckBox
					checkbox-name="terms-condition"
					:checked="checkBox"
					:label="$t('checkBox')"
					:validation-rules="{ required: { allowFalse: false } }"
					required
					:hide-text-state="true"
					:validation-messages="{ required: $t('error.required') }"
					:automation-id="getAutomationId('acknowledge')"
					class="checkbox small-width"
					@input="updateTermsCondition"
				/>
				<div>
					<BaseButton
						:label="$t('buttonAccept')"
						variant="primary"
						class="mr-2"
						:automation-id="getAutomationId('accept')"
						type="submit"
					/>
					<BaseButton
						:label="$t('buttonDecline')"
						variant="secondary"
						:automation-id="getAutomationId('decline')"
						@click="submit(false)"
					/>
				</div>
			</BaseForm>
		</BaseCard>
	</BContainer>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BContainer, BCardText } from 'bootstrap-vue';
import FormHeader from '@/components/common/form/FormHeader';
import BaseCheckBox from '@/components/common/base/BaseCheckBox';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseForm from '@/components/common/base/BaseForm';
import TermsAndConditionContentAndPrivacy from '@/components/termsAndConditions/TermsAndConditionsAndPrivacy';
import termsConditionsEn from '@/content/legal/terms-conditions-en.js';
import termsConditionsFr from '@/content/legal/terms-conditions-fr.js';

// @vue/component
@Component({
	name: 'TermsAndConditionForm',
	components: {
		BContainer,
		FormHeader,
		BCardText,
		BaseCard,
		BaseCheckBox,
		BaseButton,
		BaseForm,
		TermsAndConditionContentAndPrivacy
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Boolean,
			default: null
		}
	}
})
export default class TermsAndConditionForm extends Vue {
	checkBox = null;

	/**
	 * get the terms and condtions content
	 */
	get termsAndCondition() {
		if (this.$store.state.i18n.locale === 'en') return termsConditionsEn;
		return termsConditionsFr;
	}

	/**
	 * Emit the response value to parent component.
	 */
	submit(value) {
		this.$emit('response', value);
	}

	/**
	 * Update the checkbox value.
	 */
	updateTermsCondition(value) {
		this.checkBox = value;
	}
}
</script>
<style lang="scss" scoped>
* ::v-deep .btn {
	@media (max-width: 375px) {
		/**
		* added because at small screen level buttons were to close
		*/
		margin-top: 0.5rem;
	}
}
.checkbox {
	margin-bottom: 30px;
}
::v-deep .custom-control-label {
	font-weight: 400;
}
.small-width {
	width: 95%;
}
</style>
<i18n>
{
    "en": {
        "title": "Privacy Policy and Terms & Conditions of Use",
        "secureSite": "You Are Entering a Secure Site",
        "paragraph": {
            "pleaseRead": "Please read the Privacy Policy and Terms & Conditions before using the Member Services site. By accepting, you agree to comply with our terms and will be granted access. If you do not accept, you will not be able to access the site."
        },
        "checkBox": "I acknowledge and accept the Privacy Policy and Terms & Conditions",
        "buttonAccept": "Accept",
        "buttonDecline": "Decline",
		"error": {
			"required": "Please accept the Privacy Policy and Terms and Conditions to continue."
		}
    },
    "fr": {
        "title": "Politique de confidentialité et Conditions d'utilisation",
        "secureSite": "Vous entrez sur un site sécurisé",
        "paragraph": {
            "pleaseRead": "Veuillez lire notre Politique de confidentialité ainsi que nos Conditions d'utilisation avant d'utiliser le site Web des adhérents. En les acceptant, vous consentez à vous y conformer et aurez ainsi accès à notre site Web. Si vous les refusez, vous ne pourrez accéder au site."
        },
        "checkBox": "J'ai compris et j'accepte la Politique de confidentialité et les Conditions d'utilisation.",
        "buttonAccept": "Accepter",
        "buttonDecline": "Refuser",
		"error": {
			"required": "Veuillez accepter la Politique de confidentialité et les Conditions d'utilisation avant de poursuivre."
		}
	}
}
</i18n>
