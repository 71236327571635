export default `
<ol>
    <li class="mb-3">
        Les renseignements mentionnés dans les présentes sont fournis à titre d'information seulement. Ils ne
        constituent pas une entente ou un contrat entre vous-même et Croix Bleue. Ces renseignements résument les
        principales caractéristiques de votre régime d'assurance et n’ont pas force légale. Les modalités de votre
        régime d'assurance, y compris les exclusions, limites et définitions, sont inscrites dans la police individuelle
        en votre possession ou dans le contrat fourni à votre administrateur de régime. En cas de divergence entre les
        renseignements contenus dans votre régime et ceux qui sont affichés sur le site Web, les renseignements de votre
        régime prévalent.
    </li>
    <li class="mb-3">
        Les renseignements fournis sont tirés des données qui figurent actuellement dans nos dossiers et
        ils sont jugés fiables. Nous ne garantissons pas que ces renseignements seront exacts, complets et à jour en
        tout temps puisqu'ils peuvent être modifiés à l'occasion. Croix Bleue n'est pas responsable des dommages pouvant
        résulter de l'utilisation des renseignements contenus dans ce site.
    </li>
    <li class="mb-3">L'Association dentaire canadienne est détentrice des droits d'auteur du Guide du système de
        codification standard et du répertoire des services et l'Association des denturologistes du Canada est
        détentrice des droits d'auteur des codes et de la liste des services de denturologie.
    </li>
</ol>
`;
