/* eslint-disable vue/no-v-html */
<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BRow class="m-0 mobile-padding">
		<BCol :offset-md="1" :md="10" class="offset-md-1">
			<LoginDialog automation-id="login-page" />
		</BCol>
	</BRow>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import LoginDialog from '@/components/login/LoginDialog';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'Login',
	components: {
		BRow,
		BCol,
		LoginDialog
	},
	mixins: [IdMixin],
	data: () => ({
		user: {
			email: null,
			password: null
		},
		loading: false,
		failureCount: 0
	}),
	computed: {
		screenWidth: () => {
			return window.innerWidth;
		}
	}
})
export default class Login extends Vue {
	/**
	 *  get Login Attempts
	 */
	get loginAttempts() {
		return this.$store.state.loginAttempts;
	}
	get locale() {
		return this.$store.state.i18n.locale;
	}

	mounted() {
		this.$store.dispatch('updatePageLoadingAnimation', false);
		// Clear all stored data
		localStorage.clear();
		sessionStorage.clear();
		// Reset all Vuex orm entities
		this.$store.dispatch('entities/deleteAll');
		// Reset User, Token, loggedIn vuex store values
		this.$store.dispatch('clearSession');
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->
<style lang="scss" scoped>
$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1220px;

.text_content_area {
	margin-top: 8%;
	margin-bottom: 2%;
	margin-right: 0px;
	margin-left: 0px;

	@media screen and (max-width: 768px) {
		margin-top: 4%;
	}
	@media screen and (min-width: 1220px) {
		margin-top: 6%;
	}
}

.mobile-padding {
	width: 100%;
	padding-bottom: 50px;
	@media (max-width: 576px) {
		margin: auto;
	}
}

// _:-ms-fullscreen,
// :root .text_content_area {
// 	margin-top: 50px;
// 	height: 100px !important;
// }
// _:-ms-lang(x),
// .text_content_area {
// 	margin-top: 50px;
// 	margin-bottom: 10px;
// 	margin-right: 0px;
// 	margin-left: 0px;
// }
.icon_content {
	height: 40px;
	width: 40px;
	color: grey;
}
.full_width_container {
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0px;
	padding-left: 0px;
}
.carousel-inner {
	background-size: cover;
	background-position: center center;
}
.carousel_background {
	max-height: 575px;
	width: 100%;
	top: 0px;
	left: 0px;
	overflow: hidden;
	z-index: 0;
}
.login_horizontal_offset {
	margin-top: 12%;
}
.seperator {
	@media screen and (max-width: $break-lg) {
		width: 80%;
		height: 2px;
	}
	@media screen and (min-width: 993px) {
		width: 3px;
		height: 170px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	overflow: hidden;
	position: relative;
}
.seperator_inner {
	position: absolute;
	width: 100%;
	height: 100%;
	background: #e1e1e1;
}
.text_content_padding {
	padding: 25px;
}
</style>
