<template>
	<BaseCard class="base-card" automation-id="base-card">
		<p class="h1">{{ $t('title') }}</p>
		<hr class="line" />
		<PasswordRequirements />
		<BaseForm
			ref="change-password-form"
			:data-tracking-id="getAutomationId('change-password-form')"
			:automation-id="getAutomationId('change-password-form')"
			@submit="submitPasswordChange"
		>
			<ChangePasswordForm
				v-model="member"
				:previous-password-error="$t('error.previousPassword')"
				:show-previous-password-error="isPreviousPassword"
				:automation-id="getAutomationId('change-password')"
			/>

			<BaseButton
				class="m-0"
				:label="$t('button.save')"
				:aria-label="$t('button.save')"
				variant="primary"
				:automation-id="getAutomationId('change-password')"
				:data-tracking-id="getAutomationId('change-password')"
				type="submit"
			/>
		</BaseForm>

		<LoadingModal
			:modal-show="isLoading"
			:modal-text="''"
			:automation-id="getAutomationId('change-password-loading-modal')"
		/>
	</BaseCard>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Authentication from '@/models/Authentication';
import * as Routes from '@/constants/Routes';
import ChangePasswordForm from '@/components/login/ChangePasswordForm';
import PasswordRequirements from '@/components/common/PasswordRequirements';
import LoadingModal from '@/components/common/LoadingModal';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseForm from '@/components/common/base/BaseForm';
import IdMixin from '@/mixins/id';

// @vue/component
// This component is the change password screen for when a member has forgotten their password and cannot log in, and is
// going through the forgot password flow. The screen for a member changing their password once logged in under the my account section is located here:
// src/pages/manage-your-account/change-password/ChangePasswordPage.vue
@Component({
	name: 'ChangePassword',
	components: {
		ChangePasswordForm,
		PasswordRequirements,
		LoadingModal,
		BaseButton,
		BaseForm,
		BaseCard
	},
	mixins: [IdMixin]
})
export default class ChangePassword extends Vue {
	member = {
		password: '',
		confirm: ''
	};
	isPreviousPassword = false;

	get isLoading() {
		return this.$store.state.loading;
	}

	submitPasswordChange() {
		this.$store.dispatch('updateLoading', true);
		this.loading = true;
		Authentication.ForgotPasswordReset(
			this.member.password,
			this.$route.params.token,
			this.$store.state.i18n.locale
		)
			.then(async (response) => {
				if (response && response.status <= 204) {
					this.$router.push({ name: Routes.SUCCESS_RESET });
				} else if (response && response.status === 409) {
					this.isPreviousPassword = true;
				} else {
					this.$store.dispatch('error', { name: 'changePassword', err: response?.data?.message });
				}
			})
			.catch((err) => {
				if (err) {
					this.$store.dispatch('error', err);
				} else {
					this.$store.dispatch('error');
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
				this.loading = false;
			});
	}
}
</script>

<style lang="scss" scoped>
.card {
	width: 550px;
	margin: auto;
	box-shadow: 0px 0px 8px #2525251a;
	border-radius: 10px;
	@media (max-width: 576px) {
		width: 90%;
	}
}
.base-card {
	margin-top: 30px;
	margin-bottom: 30px;
}
.line {
	color: $gray-light;
}
</style>

<i18n>
{
    "en": 
	{
        "title": "Change your password",
		"button": {
			"save": "Save password"
		},
	"error": {
		"previousPassword": "Password cannot be the same as your last 5 passwords."
	}
	},
	"fr": 
	{
        "title": "Changer votre mot de passe",
		"button": {
			"save": "Enregistrer le mot de passe"
		},
	"error": {
		"previousPassword": "Le mot de passe ne peut pas être le même que les cinq derniers utilisés."
	}
	}
}
</i18n>
