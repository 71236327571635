<!-- *************************************************************************
	Authentication inputs
	************************************************************************* -->
<template>
	<BaseForm
		ref="authentication-form"
		:data-tracking-id="getAutomationId('authentication-form')"
		:automation-id="getAutomationId('authentication-form')"
		@keydown.enter="submit"
		@submit="onSubmit"
	>
		<!--To avoid skipped heading level when using screen readers change h3 to h2 tag  -->
		<h2 class="h3">{{ $t('title.header') }}</h2>
		<EmailInput
			:value="value.email"
			:label="$t('title.email')"
			:automation-id="getAutomationId('authentication')"
			@input="updateEmail"
		></EmailInput>
		<PasswordInput
			:value="value.password"
			:min="null"
			type="password"
			class="password-input"
			password
			:automation-id="getAutomationId('authentication')"
			:label="$t('title.password')"
			@input="updatePassword"
		></PasswordInput>
		<BaseButton
			:label="$t('title.forgotPassword')"
			:pill="false"
			variant="link"
			class="pl-0"
			:automation-id="getAutomationId('authentication-forgot-password')"
			:data-tracking-id="getAutomationId('authentication-forgot-password')"
			@click="toForgotPassword"
		/>
		<div class="mt-3">
			<BaseButton
				:label="$t('button.login')"
				:aria-label="$t('button.login')"
				variant="primary"
				:automation-id="getAutomationId('authentication-button')"
				:data-tracking-id="getAutomationId('authentication')"
				type="submit"
			/>
		</div>
		<div class="mt-3">
			<BaseButton
				:label="$t('title.troubleLogin')"
				:pill="false"
				variant="link"
				class="pl-0"
				:automation-id="getAutomationId('authentication-trouble')"
				:data-tracking-id="getAutomationId('authentication-trouble')"
				@click="toNeedHelp()"
			/>
		</div>
	</BaseForm>
</template>
<!-- ************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseForm from '@/components/common/base/BaseForm';
import BaseButton from '@/components/common/base/BaseButton.vue';
import EmailInput from '@/components/common/EmailInput';
import PasswordInput from '@/components/common/PasswordInput';
import * as Routes from '@/constants/Routes.js';
// @vue/component
@Component({
	name: 'AuthenticationForm',
	components: {
		BaseForm,
		BaseButton,
		EmailInput,
		PasswordInput
	},
	mixins: [IdMixin],
	props: {
		/**
		 * member value
		 */
		value: {
			type: Object,
			default: () => {}
		}
	}
})
export default class AuthenticationForm extends Vue {
	/**
	 * Update user password
	 * @param {string} password	user password
	 */
	updatePassword(password) {
		this.$emit('input', { ...this.value, password });
	}
	/**
	 * Update user email
	 * @param {string} email  user email
	 */
	updateEmail(email) {
		sessionStorage.setItem('email', email);
		this.$emit('input', { ...this.value, email });
	}
	toForgotPassword() {
		this.$router.push({ name: Routes.FORGOT_PASSWORD });
	}

	toNeedHelp() {
		this.$router.push({ name: Routes.NEED_HELP });
	}

	/**
	 * submit form
	 */
	onSubmit() {
		this.$emit('submit');
	}
}
</script>

<style lang="scss" scoped>
.h3 {
	line-height: 1;
	margin-bottom: 30px;
}

.password-input {
	margin-bottom: 0;
}
</style>

<i18n>
{
    "en": {
		"title": {
			"header": "Login",
			"email": "Email",
			"password": "Password",
			"forgotPassword": "Forgot your password?",
			"troubleLogin": "Are you having trouble logging in?"
		},
		"button": {
			"login": "Login"
		}
	},
	"fr": {
		"title": {
			"header": "Ouvrir une session",
			"email": "Adresse courriel",
			"password": "Mot de passe",
			"forgotPassword": "Oublié votre mot de passe?",
			"troubleLogin": "Vous éprouvez des difficultés? "
		},
		"button": {
			"login": "Ouvrir une session"
		}
	}
}
</i18n>
