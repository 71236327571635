/* eslint-disable vue/no-v-html */
<template>
	<BaseForm
		ref="reset-login-form"
		:data-tracking-id="getAutomationId('reset-login-form')"
		:automation-id="getAutomationId('reset-login-form')"
		@submit="onSubmit"
	>
		<BRow>
			<BCol cols="12" md="6">
				<CertificateIdInput
					:value="member.memberCardId"
					class="input m-0 p-0 pb-4 short-input"
					:automation-id="getAutomationId('reset-login-form')"
					@input="updateCert"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" md="6">
				<PolicyIdInput
					:value="member.policyId"
					class="input m-0 p-0 pb-4 short-input"
					:automation-id="getAutomationId('reset-login-form')"
					@input="updatePolicy"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" md="6">
				<BirthDate
					:value="member.dob"
					class="date-of-birth input short-input"
					:automation-id="getAutomationId('reset-login-form')"
					@input="updateDateOfBirth"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" md="10">
				<EmailInput
					:value="member.email"
					:label="$t('title.email')"
					class="input m-0 p-0 pb-4"
					:automation-id="getAutomationId('reset-login-form')"
					@input="updateEmail"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<PasswordRequirements />
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" md="8">
				<PasswordInput
					:vid="'resetlogin_password'"
					:value="member.password"
					:label="$t('title.password')"
					:automation-id="getAutomationId('reset-login-form')"
					required
					:min="8"
					:require-complexity="true"
					class="password-inputs m-0 p-0 pb-4"
					@input="updatePassword"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol cols="12" md="8">
				<ConfirmInput
					:field="$t('title.password')"
					password
					type="password"
					:label="$t('title.confirm')"
					:confirmation="member.password"
					class="password-inputs m-0 p-0 pb-4"
					:class="!isEnglish ? 'confirm-input-fr' : ''"
					:automation-id="getAutomationId('reset-login-form')"
					@input="updatePasswordconfirmation"
				/>
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<BaseButton
					:label="$t('button.submit')"
					class="requestbtn"
					variant="primary"
					type="submit"
					:automation-id="getAutomationId('registration-form')"
				/>
			</BCol>
		</BRow>
	</BaseForm>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import BaseForm from '@/components/common/base/BaseForm';
import PasswordRequirements from '@/components/common/PasswordRequirements';
import CertificateIdInput from '@/components/common/CertificateIdInput';
import PolicyIdInput from '@/components/common/PolicyIdInput';
import BirthDate from '@/components/common/BirthDate';
import EmailInput from '@/components/common/EmailInput';
import PasswordInput from '@/components/common/PasswordInput';
import ConfirmInput from '@/components/common/ConfirmInput';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id';
// @vue/component
@Component({
	name: 'ResetLoginForm',
	components: {
		BRow,
		BCol,
		BaseForm,
		CertificateIdInput,
		PolicyIdInput,
		BirthDate,
		EmailInput,
		PasswordInput,
		ConfirmInput,
		BaseButton,
		PasswordRequirements
	},
	mixins: [IdMixin],
	props: {
		value: {
			type: Object,
			default: () => {}
		},
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class ResetLoginForm extends Vue {
	get member() {
		return this.value ? this.value : {};
	}

	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}

	onSubmit() {
		this.$emit('submit');
	}

	updateCert(memberCardId) {
		this.$emit('input', { ...this.value, memberCardId });
	}

	updatePolicy(policyId) {
		this.$emit('input', { ...this.value, policyId });
	}

	updateDateOfBirth(dob) {
		this.$emit('input', { ...this.value, dob });
	}

	updateEmail(email) {
		this.$emit('input', { ...this.value, email });
	}

	updatePassword(password) {
		this.$emit('input', { ...this.value, password });
	}

	updatePasswordconfirmation(confirm) {
		this.$emit('input', { ...this.value, confirm });
	}
}
</script>
<style lang="scss" scoped>
li {
	font-family: 'lato', sans-serif;
	line-height: 24px;
	font-weight: 300;
}
::v-deep .optLabel {
	font-style: normal;
}
// Have to use important tags here to overwrite the bootstrap class assigned in the base component.
.date-of-birth ::v-deep .label-div {
	width: 120% !important;
	@media (max-width: 576px) {
		width: 100% !important;
	}
}
</style>
<i18n>
{
    "en": {
        "title": {
            "email": "Email address",
            "password": "Password",
            "confirm": "Confirm password"
        },
        "button": {
			"submit": "Reset your account"
		} 
    },
	 "fr": {
        "title": {
            "email": "Adresse courriel",
            "password": "Mot de passe",
            "confirm": "Confirmer le mot de passe"
        },
        "button": {
			"submit": "Réinitialiser votre compte"
		} 
    }
}
</i18n>
