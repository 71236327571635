<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<ValidationProvider
		ref="provider"
		v-slot="{ validated, failedRules, invalid }"
		class="validation-provider"
		:vid="validationId"
		:immediate="validateImmediately"
		:skip-if-empty="!validateIfEmpty"
		:rules="validationRules"
		:mode="validationMode"
		:disabled="validationDisabled"
		slim
	>
		<div class="validationGroup">
			<BFormGroup
				:id="getUniqueId('form-group')"
				:state="getState(validated, invalid)"
				@click.native="validateIfInput"
				@keydown.native.tab="validateIfInput"
			>
				<!-- added aria-hidden="true" to prevent NVDA label double reading, as radio group has aria-label attribute -->
				<label v-if="label" :for="inputId" class="label" aria-hidden="true">
					{{ label }} <span class="optLabel">{{ optionalLabel }}</span>
					<div class="slot"><slot></slot></div>
				</label>
				<BFormRadioGroup
					:id="inputId"
					class="radiogroup"
					:aria-label="fullLabel"
					v-bind="$attrs"
					:checked="value"
					:stacked="stacked"
					:accessible-required-label="accessibleRequiredLabel"
					:state="getState(validated, invalid)"
					:data-test-automation-id="getAutomationId('radio')"
					size="lg"
					@input="onInput"
				>
					<BFormRadio v-for="(option, index) in options" :key="option.value" :value="option.value">
						<span>
							{{ option.text }}
							<InfoModal
								v-if="'modal' in option"
								class="d-inline"
								:modal-title="option.modal.title"
								:button-padding="false"
								:automation-id="`base-radio-butt-group-modal-${index}`"
							>
								{{ option.modal.text }}
							</InfoModal>
						</span>
					</BFormRadio>
				</BFormRadioGroup>
			</BFormGroup>
			<BFormInvalidFeedback
				:id="getUniqueId('feedback')"
				:class="validationDisabled ? 'd-none' : ''"
				:state="getState(validated, invalid)"
				:force-show="false"
				aria-live="polite"
				:data-test-automation-id="getAutomationId('feedback')"
				>{{ getFeedback(validated, failedRules) }}
			</BFormInvalidFeedback>
		</div>
	</ValidationProvider>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import { BFormGroup, BFormRadio, BFormRadioGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import InfoModal from '@/components/common/InfoModal.vue';

// @vue/component
@Component({
	name: 'BaseRadioButtonGroup',
	components: {
		ValidationProvider,
		BFormGroup,
		BFormRadioGroup,
		BFormRadio,
		BFormInvalidFeedback,
		InfoModal
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	/**
	 * handles how the v-model is binded
	 */
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		label: {
			type: String,
			default: ''
		},
		optionalLabel: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		value: {
			type: [String, Number, Boolean],
			default: null
		},
		options: {
			type: Array,
			default() {
				return [];
			}
		},
		stacked: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the field is validated immediatly
		 */
		validateImmediately: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the validate if the field is empty
		 */
		validateIfEmpty: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls the validation id. This is needed when cross field validation
		 */
		validationId: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationMode: {
			type: String,
			default: 'eager'
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationDisabled: {
			type: Boolean,
			default: false
		},
		automationId: {
			type: String,
			required: true
		},
		/**
		 * This prop controlls "reqired" message on the label for screen reader users
		 */
		accessibleRequiredLabel: {
			type: String,
			default: ''
		}
	}
})
export default class BaseRadioButtonGroup extends Vue {
	inputId = this.getUniqueId('input');
	/**
	 * Returns the feedback
	 *
	 * @param validated {Boolean} Flag is field is validated.
	 * @param failedRules {Object} The object of failed rules.
	 */
	getFeedback(validated, failedRules) {
		let message = null;
		if (validated && failedRules) {
			message = this.validationMessages[Object.keys(failedRules)[0]];
		}
		return message;
	}

	/* This computed property gets concatenates the label, optional
		label and adds required message when group is required to be read by the screen reader.
	*/
	get fullLabel() {
		let srLabel = `${this.label} ${this.optionalLabel}`;
		return this.validationDisabled ? srLabel : `${srLabel} ${this.accessibleRequiredLabel}`;
	}
	/**
	 * Returns the state
	 *
	 * @param validated {Boolean} Flag indicating if field is validated.
	 * @param invalid {Object} Flag indicating if field is valid
	 */
	getState(validated, invalid) {
		return validated && invalid ? false : null;
	}
	/**
	 * Handle input event.
	 *
	 * @param value {String} Checked value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}

	validateIfInput() {
		this.$refs.provider.validate();
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.label {
	font-family: $josefin-font-family;
	font-size: 18px;
}
.optLabel {
	color: $label-grey;
	font-weight: 300;
	font-style: italic;
}
.validation-provider {
	margin-bottom: 1rem;
}
.form-control {
	font-family: $lato-font-family, sans-serif;
	font-weight: 400;
	background-color: $input-background-white;
	border-color: $input-border-white;
	border-radius: 5px;
	font-size: 15px;
	&:focus {
		border-color: $input-border-focus-blue;
	}
	&.is-invalid {
		border-color: $error-red;
		box-shadow: none;
	}
}
.form-group {
	margin-bottom: 0;
	& ::v-deep .custom-control-label {
		font-size: 15px;
		& span {
			vertical-align: sub;
		}
	}
	& ::v-deep .custom-control-label::before {
		border-color: $gray-dark;
		// gets us down to the 18px like in the mockup
		transform: scale(0.9);
	}
	& ::v-deep .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
		background-color: $white;
		border-color: $gray-dark;
	}
	& ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
		border-radius: 50%;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='%230079ad'/%3e%3c/svg%3e");
		transform: scale(0.8);
	}
}
.invalid-feedback {
	text-align: left;
	font-size: 14px;
	color: $error-red;
	line-height: 17px;
	font-weight: 400;
	background-color: $error-background-pink;
	border-color: $red;
	padding: 10px;
	height: auto;
	border-radius: 5px;
}
</style>
