<template>
	<div class="insurance-modal">
		<InfoModal
			:id="getUniqueId('cardinfoModal')"
			dialog-class="insurance-card-dialog"
			header-class="insurance-card-header"
			content-class="insurance-card-content"
			body-class="insurance-card-body"
			:modal-title="$t('title.card')"
			:modal-show="modalShow"
			:is-static="true"
			:automation-id="getAutomationId('insurance-card')"
			@close="close"
		>
			<div>
				<p class="label">{{ $t('label') }}</p>
			</div>
			<div class="img_background text-center my-3 py-4">
				<img
					:src="require(`@/assets/${imageInfo}`)"
					:alt="$t('imgAlt')"
					:automation-id="getAutomationId('insurance-card-image')"
				/>
			</div>
			<div>
				<BaseButton
					:label="$t('closeBtn')"
					variant="primary"
					class="close-button"
					:automation-id="getAutomationId('insurance-card-close')"
					@click="close()"
				></BaseButton>
			</div>
		</InfoModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import InfoModal from '@/components/common/InfoModal.vue';
import BaseButton from '@/components/common/base/BaseButton';
import IdMixin from '@/mixins/id';
// @vue/component
@Component({
	name: 'InsuranceCardModal',
	components: {
		InfoModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			default: ''
		}
	}
})
export default class InsuranceCardModal extends Vue {
	toggleModalShow = false;

	get modalShow() {
		return this.toggleModalShow;
	}

	open() {
		this.toggleModalShow = true;
	}

	close() {
		this.$children[0].$refs.infoModal.close();
	}

	get imageInfo() {
		return this.$store.state.i18n.locale === 'en' ? 'reg-id-new-en.svg' : 'reg-id-new-fr.svg';
	}
}
</script>
<style lang="scss" scoped>
.body {
	padding: 0;
}

.label {
	margin-left: 2em;
	font-size: $header-sub-font-size;
	width: 80%;
}

.img_background {
	background: $modal-light-grey;
}

.svg-icon *:not(g) {
	fill: red;
}

.insurance-modal ::v-deep .insurance-card-dialog {
	max-width: 540px;
	width: 90%;
	margin: auto;
}

.insurance-modal ::v-deep .insurance-card-header {
	margin-top: 1.5em;
	margin-left: 1.5em;

	& p {
		font-size: 21px;
	}
}

.insurance-modal ::v-deep .insurance-card-body {
	margin: 0em;
	padding: 0em;
}

.insurance-modal ::v-deep .btn-light {
	margin-top: -20px;
}

.close-button {
	min-width: 3em;
	margin-left: 1.5em;
	margin-bottom: 1em;
}
</style>

<i18n>
{
  "en": {
    "title": {
      "card": "Identification number and policy number"
		},
	  "imgAlt": "Blue Cross member card highlighting the location of the ID and policy numbers.",
	  "closeBtn": "Close",
    "label": "Your identification number (ID number) and policy number can be found on your member card. See example below."
  },
  "fr": {
    "title": {
      "card": "Numéro d’identification et numéro de police"
		},
	  "imgAlt": "Image d’une carte d’identification Croix Bleue sur laquelle l’emplacement du numéro d’identification et du numéro de police est mis en évidence.",
	  "closeBtn": "Fermer",
    "label": "Votre numéro d’identification et votre numéro de police se trouvent sur votre carte d’identification. Voir l’exemple ci-dessous :"
  }
}
</i18n>
