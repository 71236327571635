<!-- *************************************************************************
	TEMPLATE
	************************************************************************* -->

<template>
	<BFormGroup :id="formGroupId" :label-for="checkboxId" v-bind="$attrs">
		<ValidationProvider
			ref="provider"
			v-slot="{ validated, failedRules, invalid }"
			:vid="validationId"
			:immediate="validateImmediately"
			:skip-if-empty="!validateIfEmpty"
			:rules="validationRules"
			:mode="validationMode"
			:disabled="validationDisabled"
			slim
		>
			<BFormCheckbox
				:id="checkboxId"
				class="form-control"
				:name="checkboxName"
				v-bind="$attrs"
				:data-test-automation-id="getAutomationId('checkbox')"
				:data-tracking-id="getAutomationId('checkbox')"
				:checked="checked"
				:aria-required="required"
				:required="required"
				:aria-labelledby="ariaLabelId"
				:state="hideTextState ? null : getState(validated, invalid)"
				@change="onInput"
			>
				<span :id="ariaLabelId">{{ label }}</span>
				<slot></slot
			></BFormCheckbox>
			<BFormInvalidFeedback
				:id="getUniqueId('feedback')"
				:force-show="false"
				aria-live="polite"
				:state="getState(validated, invalid)"
				:data-test-automation-id="getAutomationId('feedback')"
				>{{ getFeedback(validated, failedRules) }}
			</BFormInvalidFeedback>
		</ValidationProvider>
	</BFormGroup>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import IdMixin from '@/mixins/id';
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationProvider } from 'vee-validate';
import { BFormGroup, BFormCheckbox, BFormInvalidFeedback } from 'bootstrap-vue';

/**
 * This component renders a checkbox.
 */
// @vue/component
@Component({
	name: 'BaseCheckBox',
	components: {
		BFormGroup,
		BFormCheckbox,
		BFormInvalidFeedback,
		ValidationProvider
	},
	mixins: [IdMixin],
	inheritAttrs: false,
	/**
	 * determines how the v-model is binded
	 */
	model: {
		prop: 'checked',
		event: 'input'
	},
	props: {
		label: {
			type: String,
			required: true,
			default: ''
		},
		// Used to access the label using aria-labelledby
		ariaLabelId: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		checked: {
			type: null,
			default: false
		},
		automationId: {
			type: String,
			required: true
		},
		required: {
			type: Boolean,
			default: false
		},
		// Boolean to determine if we want to show the state on the text for the checkbox.
		// If this flag is true when this component is in an invalid state the text will not turn red.
		hideTextState: {
			type: Boolean,
			default: true
		},
		/**
		 * this props controls the name field which is required if  we want validation
		 */
		checkboxName: {
			type: String,
			default: '',
			required: false
		},
		/**
		 * This prop controls if the field is validated immediatly
		 */
		validateImmediately: {
			type: Boolean,
			default: false
		},
		/**
		 * This prop controls if the validate if the field is empty
		 */
		validateIfEmpty: {
			type: Boolean,
			default: true
		},
		/**
		 * This prop controls the validation id. This is needed when cross field validation
		 */
		validationId: {
			type: String,
			default: ''
		},
		/**
		 * This prop controls the validation rules.
		 */
		validationRules: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls the validation messages for each rules.
		 */
		validationMessages: {
			type: [Object, String],
			default: ''
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationMode: {
			type: String,
			default: 'eager'
		},
		/**
		 * This prop controls when the validation is triggered
		 */
		validationDisabled: {
			type: Boolean,
			default: false
		}
	}
})
export default class BaseCheckBox extends Vue {
	/**
	 * The unique id for the input id
	 * @return {string}
	 */
	checkboxId = this.getUniqueId('chechkbox');

	/**
	 * The unique id for the form group
	 * @return {string}
	 */
	formGroupId = this.getUniqueId('form-group');

	/**
	 * Handle input event.
	 *
	 * @param value {String} Checked value.
	 */
	onInput(value) {
		this.$emit('input', value);
	}
	/**
	 * Returns the feedback
	 *
	 * @param validated {Boolean} Flag is field is validated.
	 * @param failedRules {Object} The object of failed rules.
	 */
	getFeedback(validated, failedRules) {
		let message = null;
		if (validated && failedRules) {
			message = this.validationMessages[Object.keys(failedRules)[0]];
		}
		return message;
	}
	/**
	 * Returns the state
	 *
	 * @param validated {Boolean} Flag indicating if field is validated.
	 * @param invalid {Object} Flag indicating if field is valid
	 */
	getState(validated, invalid) {
		return validated && invalid ? false : null;
	}
}
</script>

<!-- *************************************************************************
	STYLE
	************************************************************************* -->

<style lang="scss" scoped>
.form-control {
	box-shadow: none;
	border: none;
	height: auto;
	background-color: $white;
	padding-top: 0;
	padding-bottom: 0;
}

.form-control.is-valid,
.form-control.is-invalid {
	background-image: none;
}

::v-deep .custom-control-label {
	font-size: 15px;
}
.invalid-feedback {
	text-align: left;
	font-size: 14px;
	color: $error-red;
	line-height: 17px;
	font-weight: 400;
	border-color: $red;
	background-color: $error-background-pink;
	padding: 10px;
	height: auto;
	border-radius: 5px;
}
.form-group {
	margin-bottom: 0;
	& ::v-deep .custom-control-label {
		font-size: 15px;
	}
	& ::v-deep .custom-control-label::before {
		border-color: $gray-dark;
		// gets us down to the 18px like in the mockup
		transform: scale(1.1);
	}
	& ::v-deep .custom-control-input:checked ~ .custom-control-label::before,
	::v-deep .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
		background-color: $white;
		border-color: $gray-dark;
	}
	& ::v-deep .custom-control-input:checked ~ .custom-control-label::after {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' fill='%230079ad'/%3E%3C/svg%3E");
		transform: scale(1.3);
	}
}
</style>
