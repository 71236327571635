<template>
	<BRow v-if="!$store.state.loading">
		<BCol>
			<h1 class="mt-4 mb-3">{{ $t('pageTitle') }}</h1>
			<BRow>
				<BCol xs="12" lg="7">
					<BaseCard>
						<h3>{{ $t('mail.getHelpByEmail') }}</h3>
						<p v-if="!emailSent">{{ $t('mail.getInTouchOne') }} {{ $t('mail.getInTouchTwo') }}</p>
						<p v-else>{{ $t('mail.getInTouchTwo') }}</p>
						<BaseForm @submit="sendEmail">
							<div v-if="!emailSent">
								<BaseTextArea
									v-model="userContent"
									label=""
									:rows="5"
									:validation-rules="{ required: true }"
									:validation-messages="{ required: $t('error.textareaRequired') }"
									:automation-id="getAutomationId('contact-us-form')"
								/>
								<BaseButton
									variant="primary"
									type="submit"
									:label="$t('mail.button')"
									:automation-id="getAutomationId('contact-us-form')"
								/>
							</div>
							<div v-else>
								<BaseButton
									class="mb-2"
									:label="$t('mail.homepage')"
									variant="primary"
									:icon="['fal', 'chevron-right']"
									:automation-id="getAutomationId('contact-us-form-homepage')"
									@click="toHomepage()"
								/>
							</div>
						</BaseForm>
					</BaseCard>
				</BCol>
				<BCol xs="12" lg="5">
					<BaseCard v-if="!contactUsError" class="contact-card">
						<div v-if="showTollFreeSection">
							<span class="pre-info">{{ $t('tollFree') }}</span>
							<a class="mb-1 text-nowrap links" :href="`tel:+${contactData[locale].tollFree}`">
								{{ getPhoneFormat(contactData[locale].tollFree) }}
							</a>
							<br />
							<br />
						</div>
						<div v-if="showWebsiteSection" class="mb-4">
							<span class="mb-1 pre-info">{{ $t('website') }}</span>
							<a class="links" :href="websiteHref" target="_blank">{{
								contactData[locale].website
							}}</a>
						</div>
						<h5
							v-if="showHoursOfOperationSection || showHoursOfOperationRegionsSection"
							class="mb-0"
						>
							{{ $t('hoursOperation') }}
						</h5>
						<div v-if="showHoursOfOperationSection" class="mb-1">
							<span class="info">{{ contactData[locale].hrsOfOperation }}</span>
						</div>
						<div v-if="showHoursOfOperationRegionsSection" class="mb-1 mt-4">
							<div
								v-for="(hrsOfOperation, eKey) in contactData[locale].hrsOfOperationRegions"
								:key="eKey"
							>
								<h5 class="mb-0 pre-info">{{ hrsOfOperation.name }}</h5>
								<div
									v-if="eKey == contactData[locale].hrsOfOperationRegions.length - 1"
									class="info"
								>
									{{ hrsOfOperation.value }}
								</div>
								<div v-else class="mb-4 info">{{ hrsOfOperation.value }}</div>
							</div>
						</div>
					</BaseCard>
				</BCol>
			</BRow>
			<BaseAlert
				v-if="contactUsError"
				variant="danger"
				:icon="['fal', 'exclamation-triangle']"
				class="error-body"
			>
				{{ $t('error.header') }}
			</BaseAlert>
		</BCol>
	</BRow>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import axios from 'axios';
// mixins
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import IdMixin from '@/mixins/id';
// components
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import BaseTextArea from '@/components/common/base/BaseTextArea.vue';
import BaseForm from '@/components/common/base/BaseForm.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
// constants
import { DASHBOARD } from '@/constants/Routes.js';
// rules
import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
extend('required', required);
// models
import SecurityPolicy from '@/models/SecurityPolicy';
import MemberInfo from '@/models/MemberCardInfo';
import ContactInfo from '@/models/ContactInfo';
// utils
import { formatPhoneNumberWithParentheses as formatPhone } from '@/utils/commonUtils.js';

@Component({
	name: 'ContactUsPage',
	components: {
		BRow,
		BCol,
		BaseCard,
		BaseAlert,
		BaseTextArea,
		BaseForm,
		BaseButton
	},
	mixins: [BreadcrumbsManager, IdMixin]
})
export default class ContactUsPage extends Vue {
	contactUsError = false;
	contactData = {
		en: {},
		fr: {}
	};

	userContent = '';

	userInfo = {
		policyId: '',
		certificateId: ''
	};

	emailSent = false;

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get websiteHref() {
		if (
			!this.contactData[this.locale].website?.startsWith('https://', 0) ||
			!this.contactData[this.locale].website?.startsWith('http://', 0)
		) {
			return `https://${this.contactData[this.locale].website}`;
		}
		return this.contactData[this.locale].website;
	}

	async created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('url.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('url.contactUs', 'en') }
			],
			[
				{ text: this.$t('url.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('url.contactUs', 'fr') }
			]
		);

		await SecurityPolicy.getSecurityPolicy(sessionStorage.getItem('email'));
		await this.getInfo();
	}

	async updated() {
		if (Object.keys(this.contactData[this.locale]).length === 0) {
			await this.getInfo();
		}
	}

	get showTollFreeSection() {
		return this.contactData[this.locale].tollFree;
	}

	get contactEmail() {
		return this.contactData[this.locale].email;
	}

	get showWebsiteSection() {
		return this.contactData[this.locale].website;
	}

	get showHoursOfOperationSection() {
		return this.contactData[this.locale].hrsOfOperation;
	}

	get showHoursOfOperationRegionsSection() {
		return this.contactData[this.locale].hrsOfOperationRegions;
	}

	async getInfo() {
		try {
			this.$store.dispatch('updateLoading', true);
			const email = sessionStorage.getItem('email');
			const token = sessionStorage.getItem('apiToken');
			const json = await MemberInfo.getResourceUrlsByEmail(email, token, this.locale);
			const res = await axios.get(encodeURI(json.data.json_contactUrl));
			this.contactData[this.locale] = { ...res.data };
			this.$store.dispatch('updateLoading', false);
			this.contactUsError = false;
			this.userInfo = await MemberInfo.getMemberDetail(email, token, this.locale);
		} catch (err) {
			this.$store.dispatch('updateLoading', false);
			this.contactUsError = true;
		}
	}

	getPhoneFormat(phone) {
		return formatPhone(phone);
	}

	async sendEmail() {
		try {
			this.$store.dispatch('updateLoading', true);
			let response = await ContactInfo.sendContactUsEmail(
				sessionStorage.getItem('email'),
				this.userContent,
				sessionStorage.getItem('apiToken'),
				this.locale
			);
			if (response.status <= 203) {
				if (!this.$route.params.hasSucceeded) {
					this.$store.dispatch('successBanner', {
						show: true,
						headerText: this.$t('mail.successTitle'),
						text: this.$t('mail.successMessage')
					});
					this.emailSent = true;
				}
			} else {
				this.$store.dispatch('error', { name: 'contactUs', err: this.$t('error.apiError') });
			}
		} catch (error) {
			this.$store.dispatch('error', { name: 'contactUs', err: this.$t('error.apiError') });
		} finally {
			this.$store.dispatch('updateLoading', false);
		}
	}

	toHomepage() {
		this.$router.push({ name: DASHBOARD });
	}
}
</script>

<style lang="scss" scoped>
.pre-info {
	font-family: $font-family-regular;
	font-size: 17px;
	font-weight: bold;
}
.info {
	font-family: $font-family-regular;
	font-size: 17px;
	font-weight: 100;
}
.links {
	font-family: $font-family-regular;
	font-weight: 500;
}
.contact-card {
	width: 100%;
	max-width: 550px;
}
.error-body {
	width: 100%;
}
</style>

<i18n>
{
	"en": {
		"pageTitle": "Contact Us",
		"tollFree": "Toll-free customer service: ",
		"hoursOperation": "Hours of Operation",
		"website": "Website: ",
		"url": {
			"home": "Home",
			"contactUs": "Contact Us"
		},
		"mail": {
			"getHelpByEmail": "Get help by email",
			"getInTouchOne": "To get in touch with us by email, use the secure form below. ",
			"getInTouchTwo": "A representative will reply as soon as possible with a secure email.",
			"button": "Submit",
			"homepage": "Return to homepage",
			"successTitle": "Thank you!",
			"successMessage": "Your message has been successfully sent."
		},
		"error": {
			"header": "We're sorry - something went wrong with our Contact Us page. We're working on a fix",
			"textareaRequired": "Please enter a message.",
			"apiError": "Sorry, we couldn't send your message. Please try again."
		}
	},
	"fr": {
		"pageTitle": "Communiquez avec nous",
		"tollFree": "Numéro sans frais du Service à la clientèle : ", 
		"hoursOperation": "Heures d'ouverture",
		"website": "Site Web : ",
		"url": {
			"home": "Accueil",
			"contactUs": "Communiquez avec nous"
		},
		"mail": {
			"getHelpByEmail": "Obtenir de l'aide par courriel",
			"getInTouchOne": "Pour nous joindre par courriel, utilisez le formulaire sécurisé ci-dessous. ",
			"getInTouchTwo": "Un représentant ou une représentante vous répondra dans les plus brefs délais par courriel sécurisé.",
			"button": "Soumettre",
			"homepage": "Retour à la page d'accueil",
			"successTitle": "Merci!",
			"successMessage": "Votre message a bien été envoyé."
		},
		"error": {
			"header": "Nous sommes désolés, une erreur s’est produite sur notre page Communiquez avec nous. Nous tentons de trouver une solution.",
			"textareaRequired": "Veuillez entrer un message.",
			"apiError": "Désolés, nous n'avons pas été en mesure d'envoyer votre message. Veuillez réessayer."
		}
	}
}
</i18n>
