<template>
	<BRow align-h="center">
		<BCol lg="8">
			<FormHeader
				class="header"
				:title="$t('title')"
				:automation-id="getAutomationId('custom-contact')"
			/>

			<BCard>
				<template #header>
					<img
						class="main-image"
						:src="require(`@/assets/custom-contact.jpg`)"
						alt="imgAlt"
						:test-automation-id="getAutomationId('card-top-image')"
					/>
				</template>

				<div class="form">
					<p class="h3 pb-1">{{ $t('tagLine') }}</p>
					<h5 v-if="showHoursOfOperationSection || showHoursOfOperationRegionsSection" class="mb-0">
						{{ $t('hoursOperation') }}
					</h5>
					<div v-if="showHoursOfOperationSection" class="mb-1">
						<span class="info">{{ contactData[locale].hrsOfOperation }}</span>
						<br />
					</div>

					<div v-if="showHoursOfOperationRegionsSection" class="mb-1 mt-4">
						<div
							v-for="(hrsOfOperation, eKey) in contactData[locale].hrsOfOperationRegions"
							:key="eKey"
						>
							<h5 class="mb-0 pre-info">{{ hrsOfOperation.name }}</h5>
							<div v-if="eKey == contactData[locale].hrsOfOperationRegions.length - 1" class="info">
								{{ hrsOfOperation.value }}
							</div>
							<div v-else class="mb-4 info">{{ hrsOfOperation.value }}</div>
						</div>
						<br />
					</div>
					<a class="phone-number" :href="'tel:' + phoneNumber">{{ phoneNumber }}</a>
				</div>
			</BCard>
		</BCol>
	</BRow>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import FormHeader from '@/components/common/form/FormHeader';
import memberCardInfo from '@/models/MemberCardInfo';
import { BCard, BRow, BCol } from 'bootstrap-vue';
import { formatPhoneNumber } from '@/utils/commonUtils.js';
import axios from 'axios';
import IdMixin from '@/mixins/id';

// @vue/component
@Component({
	name: 'CustomContact',
	components: {
		BCard,
		BRow,
		BCol,
		FormHeader
	},
	mixins: [IdMixin],

	watch: {
		async locale() {
			await this.getContactInfo();
		}
	}
})
export default class CustomContact extends Vue {
	hours = null;
	phoneNumber = null;
	token = null;
	contactData = {
		en: {},
		fr: {}
	};

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get showHoursOfOperationSection() {
		return this.contactData[this.locale].hrsOfOperation;
	}

	get showHoursOfOperationRegionsSection() {
		return this.contactData[this.locale].hrsOfOperationRegions;
	}

	created() {
		if (this.$route.query?.token) {
			this.token = this.$route.query?.token;
		}
		this.getContactInfo();
	}

	getContactInfo() {
		this.$store.dispatch('updateLoading', true);
		var formattedToken;
		if (this.$route.query?.token) {
			formattedToken = this.$route.query.token.replaceAll(' ', '+');
			this.getResourceUrls(formattedToken);
		} else if (this.token) {
			formattedToken = this.token.replaceAll(' ', '+');
			this.getResourceUrls(formattedToken);
		} else if (this.$route.params?.token) {
			formattedToken = this.$route.params.token.replaceAll(' ', '+');
			this.getResourceUrls(formattedToken);
		} else if (sessionStorage.getItem('mslToken')) {
			this.getResourceUrls(sessionStorage.getItem('mslToken'));
		} else {
			this.hours = this.$t('default.hours');
			this.phoneNumber = this.$t('default.phoneNumber');
			this.$store.dispatch('updateLoading', false);
		}
	}

	getResourceUrls(token) {
		memberCardInfo
			.getResourceUrls(token, this.$store.state.i18n.locale)
			.then((response) => {
				if (response && (response.status === 201 || response.status === 200)) {
					let secureUrl = response.data.json_contactUrl.replace('http://', 'https://');
					axios
						.get(encodeURI(secureUrl))
						.then((jsonResponse) => {
							this.contactData[this.locale] = { ...jsonResponse.data };
							this.phoneNumber = formatPhoneNumber(jsonResponse.data.tollFree);
						})
						.catch((error) => {
							//displaying the default hours and contact numbers when axios fails
							if (error) {
								this.hours = this.$t('default.hours');
								this.phoneNumber = this.$t('default.phoneNumber');
							}
						});
				} else {
					this.hours = this.$t('default.hours');
					this.phoneNumber = this.$t('default.phoneNumber');
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
			});
	}
}
</script>

<style lang="scss" scoped>
.main-image {
	width: 100%;
	height: 200px;
	border-radius: 10px 10px 0px 0px;
	object-fit: cover;
	object-position: 50% 5%;
	@media (max-width: 600px) {
		height: 150px;
	}
	@media (max-width: 400px) {
		height: 120px;
	}
}

.card-header {
	padding: 0;
}

.header {
	text-align: center;
}

.phone-number {
	color: $blue;
	font-size: 21px;
	font-weight: bold;

	&:hover {
		color: $dark-blue;
	}
}
</style>

<i18n>
{
  "en": {
    "title": "Have a question or need some help?",
    "tagLine": "Reach out to us",
	"hoursOperation": "Hours of Operation",
    "default": {
      "phoneNumber": "1-800-667-4511",
      "hours": "8:00 a.m. - 8:00 p.m. Local time (Ontario, Quebec and Atlantic Canada) Monday - Friday"
    }
  },
  "fr": {
    "title": "Vous avez une question ou avez besoin d’aide?",
    "tagLine": "Téléphonez-nous au",
	"hoursOperation": "Heures d'ouverture",
    "default": {
      "phoneNumber": "1-800-667-4511",
      "hours": "8 h à 20 h, heure locale (au Québec, en Ontario et dans l'est du Canada) Lundi au vendredi"
    }
  }
}
</i18n>
