<template>
	<BContainer class="my-4 container-width">
		<BRow class="justify-content-center">
			<BCol>
				<BaseCard :title="$t('title.primary')" title-tag="h1">
					<p class="sub-title">{{ $t('title.secondary') }}</p>
					<hr class="divider" />
					<BaseAlert
						v-if="APIError"
						v-scroll-to
						variant="danger"
						:icon="['fal', 'exclamation-triangle']"
						class="error-body"
					>
						{{ APIError }}
					</BaseAlert>
					<ResetLoginForm
						v-model="member"
						:error-message="APIError"
						:automation-id="'reset-login'"
						@submit="submitReset"
					/>

					<LoadingModal :modal-show="isLoading" :automation-id="'reset-login'" />
				</BaseCard>
			</BCol>
		</BRow>
		<BRow class="justify-content-center">
			<BCol>
				<BaseButton
					:label="$t('button.cancel')"
					variant="link"
					class="p-0 return-button"
					:automation-id="'reset-login'"
					@click="returnToLogin()"
				/>
			</BCol>
		</BRow>
	</BContainer>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Authentication from '@/models/Authentication';
import { BContainer, BRow, BCol } from 'bootstrap-vue';
import LoadingModal from '@/components/common/LoadingModal';
import BaseCard from '@/components/common/card/BaseCard';
import BaseButton from '@/components/common/base/BaseButton';
import ResetLoginForm from '@/components/login/ResetLoginForm';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import IdMixin from '@/mixins/id';
import * as Routes from '@/constants/Routes.js';

// @vue/component
@Component({
	name: 'ResetLogin',
	components: {
		BContainer,
		BRow,
		BCol,
		BaseButton,
		BaseAlert,
		ResetLoginForm,
		LoadingModal,
		BaseCard
	},
	mixins: [IdMixin]
})
export default class ResetLogin extends Vue {
	member = {
		email: '',
		dob: '',
		memberCardId: '',
		policyId: '',
		password: ''
	};

	APIErrorEn = null;
	APIErrorFr = null;

	get APIError() {
		return this.$store.state.i18n.locale === 'en' ? this.APIErrorEn : this.APIErrorFr;
	}

	returnToLogin() {
		this.$router.push({ name: Routes.LOGIN });
	}
	/**
	 * Registration API call
	 * it is the same call as registration but will return different response
	 */
	submitReset() {
		let locale = this._i18n.locale;
		this.$store.dispatch('updateLoading', true);
		// reset the API error just in case it is set.
		this.APIError = null;
		Authentication.register(this.member, locale)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					if (!response.data.isReregistering) {
						this.$router.push({ name: Routes.SUCCESS_RESET_LOGIN });
					} else {
						this.$router.push({ name: Routes.INVALID_EMAIL });
					}
				} else if (response?.status === 401 && response?.data?.message) {
					this.APIErrorEn = response.data.messageEn;
					this.APIErrorFr = response.data.messageFr;
					this.$store.dispatch('updateLoading', false);
				} else {
					this.$store.dispatch('error', {
						name: 'generic'
					});
				}
			})
			.finally(() => {
				this.$store.dispatch('updateLoading', false);
			});
	}

	get isLoading() {
		return this.$store.state.loading;
	}
}
</script>
<style lang="scss" scoped>
.container-width {
	max-width: 600px;
}
.sub-title {
	font-size: $h4-font-size;
}
.divider {
	color: #e9e9e9;
}
</style>
<i18n>
{
  "en": {
    "title": {
      "primary": "Reset your login information",
      "secondary": "Enter your information to request access."
    },
    "button": {
      "cancel": "Cancel, take me back to login"
    }
  },
  "fr": {
    "title": {
      "primary": "Réinitialiser vos informations de connexion",
      "secondary": "Entrez vos informations pour demander un accès."
    },
    "button": {
      "cancel": "Annuler et retourner à la page d’ouverture de session"
    }
  }
}
</i18n>
