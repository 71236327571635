<template>
	<BContainer class="p-0 h-100 loginDialog">
		<FormHeader
			:title="$t('title.header')"
			:secondary-title="$t('title.secondHeader')"
			:automation-id="getAutomationId('login-dialog')"
		/>
		<BaseCard
			:img-src="require('@/assets/mobile-father-son-background-online-enrolment-sm.jpg')"
			img-alt=""
			img-top
			:img-height="200"
			two-columns
			:automation-id="getAutomationId('login-dialog')"
		>
			<template #error-message>
				<div v-if="loginError || timeoutError || APIError !== null || lockedError">
					<BaseAlert
						v-if="loginError"
						variant="danger"
						:icon="['fal', 'exclamation-triangle']"
						class="error-body"
					>
						{{ $t('error.header') }}
						<template #additional-content>
							{{ $t('error.invalidLogin') }}
							<BLink :to="helpRoute">{{ $t('error.redirectToHelpText') }}</BLink>
						</template>
					</BaseAlert>
					<BaseAlert
						v-if="timeoutError"
						variant="danger"
						:icon="['fal', 'exclamation-triangle']"
						class="error-body"
					>
						{{ $t('error.timeout') }}
					</BaseAlert>
					<BaseAlert
						v-if="lockedError"
						variant="danger"
						:icon="['fal', 'exclamation-triangle']"
						class="error-body"
					>
						{{ $t('error.locked') }}
					</BaseAlert>

					<BaseAlert
						v-if="APIError !== null"
						variant="danger"
						:icon="['fal', 'exclamation-triangle']"
						class="error-body"
					>
						{{ APIError }}
						<BaseButton
							v-if="unverifiedError"
							:label="$t('button.resendEmail')"
							variant="link"
							:automation-id="getAutomationId('login-dialog-resend-email')"
							@click="resendEmail()"
						/>
					</BaseAlert>
				</div>
			</template>
			<template #left>
				<AuthenticationForm
					v-model="user"
					:automation-id="getAutomationId('login-dialog')"
					@submit="onSubmit"
				/>
			</template>
			<template #right>
				<!--To avoid skipped heading level when using screen readers change h4 to h3 tag  -->
				<h3 class="h4">{{ $t('title.noAccount') }}</h3>
				<p class="instructions">{{ $t('label.easyAccount') }}</p>
				<ul class="access-list">
					<li>{{ $t('label.trackClaims') }}</li>
					<li>{{ $t('label.review') }}</li>
					<li>{{ $t('label.claimHistory') }}</li>
					<li>{{ $t('label.muchMore') }}</li>
				</ul>

				<BaseButton
					:label="$t('button.registerUser')"
					class="registration-button"
					:automation-id="getAutomationId('login-dialog-register')"
					:data-tracking-id="getAutomationId('login-dialog-register')"
					@click="toRegistration()"
				/>
			</template>
		</BaseCard>
	</BContainer>
</template>

<!-- *************************************************************************
	SCRIPT
	************************************************************************* -->

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BaseButton from '@/components/common/base/BaseButton';
import BaseAlert from '@/components/common/alert/BaseAlert.vue';
import AuthenticationForm from '@/components/login/AuthenticationForm';
import FormHeader from '@/components/common/form/FormHeader';
import BaseCard from '@/components/common/card/BaseCard';
import Authentication from '@/models/Authentication';
import TermsConditions from '@/models/TermsCondition';
import SecurityPolicy from '@/models/SecurityPolicy';
import { BContainer, BLink } from 'bootstrap-vue';
import * as Routes from '@/constants/Routes.js';
import { checkDemoUser } from '@/utils/demoutils';
import { makeDemoServer } from '@/mirage/demoServer';
import IdMixin from '@/mixins/id';
// @vue/component
@Component({
	name: 'LoginDialog',
	components: {
		BContainer,
		BLink,
		AuthenticationForm,
		FormHeader,
		BaseCard,
		BaseButton,
		BaseAlert
	},
	mixins: [IdMixin],
	props: {
		/**
		 * member value
		 */
		value: {
			type: Object,
			default: () => {}
		}
	}
})
export default class LoginDialog extends Vue {
	user = {
		email: null,
		password: null
	};

	loginError = false;
	lockedError = false;
	APIErrorEn = null;
	APIErrorFr = null;
	errorTitle = null;
	errorContent = null;
	unverifiedError = false;
	timeoutError = false;
	helpRoute = Routes.NEED_HELP;

	get APIError() {
		return this.$store.state.i18n.locale === 'fr' ? this.APIErrorFr : this.APIErrorEn;
	}

	set APIError(value) {
		if (this.$store.state.i18n.locale === 'fr') {
			this.APIErrorFr = value;
		} else {
			this.APIErrorEn = value;
		}
	}

	async onSubmit() {
		this.$store.dispatch('updateLoading', true);
		if (checkDemoUser(this.user) && !this.$demoServer) {
			sessionStorage.setItem('demoActive', true);
			Vue.prototype.$demoServer = makeDemoServer();
		}

		try {
			const email = sessionStorage.getItem('email');
			this.loginError = false;
			// reset the API error just in case it is set.
			this.APIError = null;
			const response = await Authentication.login(
				email,
				this.user.password,
				this.$store.state.i18n.locale
			);
			this.unverifiedError = false;
			this.lockedError = false;
			if (response?.status === 200) {
				const user = { token: response.headers['x-auth'], email };
				sessionStorage.setItem('email', user.email);
				sessionStorage.setItem('apiToken', 'Bearer ' + user.token);
				sessionStorage.setItem('token', user.token);
				sessionStorage.setItem('loggedIn', true);
				this.$store.dispatch('UpdateLogin', true);
				this.$store.dispatch('updateSessionUser', user.email);
				this.$store.dispatch('updateToken', user.token);
				await this.checkSecurityPolicy(user);
			} else if (response?.status === 401 && response.data.message !== 'INVALID') {
				this.$store.dispatch('updateLoading', false);
				if (response.data.message === 'UNVERIFIED') {
					this.APIErrorEn = this.$t('error.unverified');
					this.APIErrorFr = this.$t('error.unverified');
					this.unverifiedError = true;
				} else if (response.data.message === 'LOCKED') {
					this.lockedError = true;
					this.errorTitle = this.$t('error.header');
					this.errorContent = this.$t('error.locked');
				} else {
					this.APIErrorEn = response.data.messageEn;
					this.APIErrorFr = response.data.messageFr;
				}
			} else {
				this.$store.dispatch('updateLoading', false);
				this.loginError = true;
				this.errorTitle = this.$t('error.header');
				this.errorContent = this.$t('error.invalidLogin');
			}
		} catch (err) {
			this.loginError = true;
			this.errorTitle = this.$t('error.header');
			this.errorContent = this.$t('error.invalidLogin');
			this.$store.dispatch('updateLoading', false);
		}
	}

	async checkSecurityPolicy(user) {
		const securityPolicyResponse = await SecurityPolicy.getUserSecurityPolicies(
			user.email,
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale
		);
		if (securityPolicyResponse?.status < 201) {
			if (securityPolicyResponse.data) {
				await this.checkTAndC(user, securityPolicyResponse.data);
			}
		} else {
			this.$store.dispatch('updateLoading', false);
			if (
				securityPolicyResponse.data.messageEn &&
				securityPolicyResponse.data.messageEn !== '' &&
				securityPolicyResponse.data.messageFr &&
				securityPolicyResponse.data.messageFr !== ''
			) {
				this.APIErrorEn = securityPolicyResponse.data.messageEn;
				this.APIErrorFr = securityPolicyResponse.data.messageFr;
			} else {
				this.loginError = true;
				this.errorTitle = this.$t('error.header');
				this.errorContent = this.$t('error.tryAgain');
			}
		}
	}

	async checkTAndC(user, securityPolicy) {
		const response = await TermsConditions.hasAcceptedTAC(user, this.locale);
		const route = () => {
			if (this.$router.currentRoute.query.page === 'claim-history') {
				if (!securityPolicy.maaxClaims) {
					return Routes.CLAIM_HISTORY;
				} else {
					return Routes.HISTORY;
				}
			} else {
				return false;
			}
		};
		if (response?.status < 201) {
			if (response?.data.termsAndConditionsAccept) {
				if (route()) {
					this.$router.push({ name: route() });
				} else {
					this.$router.push({ name: Routes.DASHBOARD, params: { user: user } });
				}
			} else {
				this.$store.dispatch('updateLoading', false);
				this.$router.push({
					name: Routes.TERMS_AND_CONDITIONS_FORM,
					params: route() ? { page: route() } : {}
				});
			}
		} else {
			this.loginError = true;
			this.errorTitle = this.$t('error.header');
			this.errorContent = this.$t('error.tryAgain');
		}
	}

	toRegistration() {
		this.$router.push({
			name: Routes.REGISTRATION
		});
	}

	async mounted() {
		this.loginError = false;
		this.timeoutError = false;
		if (this.$router.history.current.query.status === 'TIMEOUT') {
			this.timeoutError = true;
		} else if (this.$router.history.current.query.status === 'INVALIDLOGIN') {
			this.loginError = true;
		}
	}

	async resendEmail() {
		this.$store.dispatch('updateLoading', true);
		//in the case a user removes their email and try to resend the email
		let email = sessionStorage.getItem('email');
		await Authentication.resendRegistrationEmail(email);
		this.$store.dispatch('updateLoading', false);
	}
}
</script>

<style lang="scss" scoped>
.instructions {
	font-weight: 400;
}
.access-list {
	margin-bottom: 20px;
}
.error-body {
	width: 100%;
}
.card ::v-deep img {
	object-position: top;
	@include media-breakpoint-down(xs) {
		height: 120px;
	}
}
</style>

<!-- *************************************************************************
	TRANSLATIONS
	************************************************************************* -->

<i18n>
{
    "en": {
		"title": {
			"header": "Welcome to Member Services",
			"secondHeader": "Log in to see your account information, check your coverage and see what's happening with your claims.",
			"noAccount": "Don't have an account?"
		},
		"label": {
			"easyAccount": "Creating your account is quick and easy! Once you've registered, you'll have access to:",
			"trackClaims": "Submit your claims online",
			"review": "Review your coverage and benefit details",
			"claimHistory": "See your claims history",
			"muchMore": "And much more!"
		},
		"button": {
			"registerUser": "Register",
			"resendEmail": "Resend activation email?"
		},
		"error": {
			"header": "Sorry, we're having trouble logging you in right now.",
			"tryAgain": "Please try again later.",
			"invalidLogin": "If you've forgotten your password, you can ",
			"redirectToHelpText": "reset your account here.",
			"timeout": "Due to inactivity, we’ve signed you out of your account. We do this to protect your information.",
			"locked": "For security purposes, your account has been temporarily locked. Please try again later.",
			"unverified": "Your account has not been activated."
		}
	},
	 "fr": {
		"title": {
			"header": "Bienvenue au site web des adhérents",
			"secondHeader": "Ouvrez une session pour consulter les renseignements de votre compte, vérifier votre couverture et connaître le statut de vos demandes de règlement.",
			"noAccount": "Vous n’avez pas de compte? "
		},
		"label": {
			"easyAccount": "Rien de plus simple que de créer un compte! Une fois votre inscription terminée, vous aurez accès aux fonctions suivantes :",
			"trackClaims": "Soumettre vos demandes de règlement en ligne",
			"review": "Consulter votre couverture et le détail de vos garanties",
			"claimHistory": "Connaître l’historique de vos demandes de règlement",
			"muchMore": "Et bien plus encore!"
		},
		"button": {
			"registerUser": "Inscrivez-vous",
			"resendEmail": "Renvoyez le courriel d'activation?"
		},
		"error": {
			"header": "Désolés, nous avons de la difficulté à ouvrir votre session pour le moment.",
			"tryAgain": "Veuillez réessayer plus tard.",
			"invalidLogin": "Si vous avez oublié votre mot de passe, vous pouvez ",
			"redirectToHelpText": "réinitialiser votre compte ici.",
			"timeout": "En raison de l'inactivité, vous avez été déconnecté de votre compte. Cette mesure vise à protéger vos renseignements. ",
			"locked": "Pour des raisons de sécurité, votre compte est temporairement bloqué. Veuillez réessayer plus tard.",
			"unverified": "Votre compte n'a pas été activé."
		}
	}
}
</i18n>
